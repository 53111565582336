import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { height, styled } from "@mui/system";
import {
  MediaFullscreenButton,
  MediaGesture,
  MediaMenu,
  MediaMenuButton,
  MediaMenuItems,
  MediaOutlet,
  MediaPlayButton,
  MediaPlaybackRateMenuButton,
  MediaPlaybackRateMenuItems,
  MediaPlayer,
  MediaPoster,
  MediaQualityMenuButton,
  MediaQualityMenuItems,
  MediaSeekButton,
  MediaSliderValue,
  MediaTime,
  MediaTimeSlider,
  MediaTooltip,
  MediaVolumeSlider,
  useMediaStore,
} from "@vidstack/react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useDraggable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import restrictToWindowEdgesMargin from "../../utils/restrictToWindowEdges";
import { pxToRem } from "px2rem2px";
import { CSS } from "@dnd-kit/utilities";
import EndView from "./EndView";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import PresentationImg from "../../images/presentation-img.jpg";
import AudioImg from "../../images/Audio.svg";
import { ReactComponent as Next } from "../../images/next.svg";
import { ReactComponent as Play } from "../../images/play.svg";
import { ReactComponent as Pause } from "../../images/pause.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as Fullscreen } from "../../images/fullscreen.svg";
import { ReactComponent as FullscreenExit } from "../../images/full-screen-exit.svg";
import { ReactComponent as Settings } from "../../images/settings.svg";
import { ReactComponent as Close } from "../../images/close.svg";
import { ReactComponent as NewWindow } from "../../images/open-in-new-window.svg";
import { ReactComponent as Setting } from "../../images/setting.svg";
import { ReactComponent as Reverse } from "../../images/reverse.svg";
import { ReactComponent as Forward } from "../../images/forward.svg";
import SidePanel from "./SidePanel";
import { updateProgress } from "../../api/course";
import Toast from "../Toast";

const IconButtonStyled = styled(IconButton)(() => {
  return {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      "& svg": {
        color: "#FCE181",
      },
    },
  };
});

const PlayerBtn = (props) => {
  return (
    <Button
      variant="yellow"
      startIcon={props.icon}
      sx={{
        height: pxToRem(54),
        px: pxToRem(40),
        "&:hover svg": { color: "#026670" },
      }}
    >
      {props.children}
    </Button>
  );
};

export default function VideoPlayerDraggable({
  resetPosition,
  x,
  y,
  preview,
  selectedLesson,
  openResourcesTab,
  startTime,
  // setPlayIcon,
  setPlayIcon = () => {},
  playIcon,
  setVideoEnded = () => {},
  videoEnded,
  noListen,

  // setNextLecture = () => {},
  setNextLecture,
  nextLecture,
  lastLesson,
  isCourseLoading,
  id,
  bookmarkStartTime,
  setBookmarkStartTime =()=>{},
  handleNextLectureClick = () => {},
  lessons,
  mediaControlsHeight = 64,
  modalScreen = false,
  setBookmarkListChanged,
  setNoteListChanged,
  setBlockAutoLectureId = () => {},

  currentTime,
  setCurrentTime = ()=> {},
}) {
  const [firstTimeLoaded ,setFirstTimeLoaded]=useState(true)


  // shareicon state
  const [toastOpen, setToastOpen] = useState(false);
  // shareicon state
  const playerWrapper = useRef(null);
  const player = useRef(null);

  const { fullscreen } = useMediaStore(player);
  const currentTimeRef = useRef();
  // const [currentTime, setCurrentTime] = useState(startTime);

  const [isListening, setIsListening] = useState(false);
  const [isWatching, setIsWatching] = useState(false);

  const [showButtons, setShowButtons] = useState(true);

  const [isMediaReady, setIsMediaReady] = useState(false);

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(() => {
  //   if (player.current && startTime) {
  //     player.current.currentTime = startTime;
  //   }
  // }, [selectedLesson]);

  const [isFixed, setIsFixed] = useState(false);
  const [playTriggered, setPlayTriggered] = useState(false);
  const [pauseTriggered, setPauseTriggered] = useState(false);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "draggable",
    disabled: !isFixed,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const scrollTargetPosition =
    playerWrapper?.current?.getBoundingClientRect().top + window.scrollY;

  function onProviderSetup(event) {
    const provider = event.detail;
    if (provider.video) {
      provider.video.setAttribute("disablePictureInPicture", "");
    }
  }

  useEffect(() => {
    const callbackFunction = (entries) => {
      const [entry] = entries;
      if (!mobile_ssm) {
        setIsFixed(!entry.isIntersecting);
      }
      resetPosition();
    };

    let observerRefValue = null;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(callbackFunction, options);

    if (playerWrapper.current) {
      observer.observe(playerWrapper.current);
      observerRefValue = playerWrapper.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  function onPlay(event) {
    
    // alert('called on play')
    // console.log("event:", event);
    // console.log("event.request:", event.request);
    // console.log("event.request.trigger:", event.request.trigger);
    // console.log("event.request.trigger.MEDIA_GESTURE:", event.request.trigger.MEDIA_GESTURE);
    if (event && event.request && event.request.trigger) {
      // alert("called on play inside if")
      // setPlayTriggered(true);
      // if (setPlayIcon) setPlayIcon(true);
    }
    setPlayTriggered(true);
    setPauseTriggered(false);
    if (setPlayIcon) setPlayIcon(true);
  }

  function onPause(event) {
    if (event.request?.trigger.MEDIA_GESTURE) {
      setPauseTriggered(true);
    }
    setPlayTriggered(false);
    if (setPlayIcon) setPlayIcon(false);
  }

  function handlePrevClick() {
    // console.log("Prev Clicked");
  }

  function handleNextClick() {
    // console.log("Next clicked");
  }

  // Determine the current lesson ID based on whether selectedLesson or preview is being used
  const currentLessonId = selectedLesson?.id;

  const postProgress = async () => {
    console.log("debugprogress postProgress called");
    console.log("debugprogress Values before condition check:", {
      playTriggered,
      currentLessonId,
      playIcon,
    });

    console.log("debugprogress Proceeding with postProgress");

    let payload = {
      watch_time: currentTimeRef.current,
      lesson: selectedLesson?.id || preview?.id,
    };
    console.log("debugprogress Payload:", payload);

    try {
      const res = await updateProgress(payload); // Await the updateProgress call
      console.log("debugprogress Update progress response:", res);
      // Handle success response (optional)
    } catch (err) {
      // Handle error response
      console.error("debugprogress Error updating progress:", err);
    }
  };

  useEffect(() => {
    console.log("debugprogress useEffect triggered with dependencies:", {
      currentLessonId,
      playTriggered,
    });

    if (playTriggered && currentLessonId) {
      const intervalId = setInterval(postProgress, 1000);
      return () => {
        clearInterval(intervalId);
        console.log("debugprogress Interval cleared");
      };
    }
  }, [playTriggered, currentLessonId]);

  // const postProgress = async () => {
  //   console.log("debugprogress progress called");
  //   if (!playTriggered || !currentLessonId || playIcon) return; // Exit if playTriggered is false
  //   console.log("debugprogress progress Posted")

  //   let payload = {
  //     watch_time: currentTimeRef.current,
  //     lesson: selectedLesson?.id || preview?.id,
  //   };

  //   try {
  //     const res = await updateProgress(payload); // Await the updateProgress call
  //     // Handle success response (optional)
  //   } catch (err) {
  //     // Handle error response
  //     console.error("Error updating progress:", err);
  //   }
  // };

  // useEffect(() => {
  //   console.log("debugprogress currentLessonId,playTriggered")
  //   const intervalId = setInterval(postProgress, 1000);
  //   return () => clearInterval(intervalId);
  // }, [currentLessonId, playTriggered]);

  // const postProgress = () => {
  //   let payload = {
  //     watch_time: currentTimeRef.current,
  //     lesson: selectedLesson?.id || preview?.id,
  //   };
  //   updateProgress(payload)
  //     .then((res) => {
  //     })
  //     .catch((err) => {
  //     });
  // };
  // useEffect(() => {
  //   const intervalId = setInterval(postProgress, 5000);
  //   return () => clearInterval(intervalId);
  // }, [currentLessonId]);

  //start: loading delay rendering the component until image is ready
  const [shouldRenderContent, setShouldRenderContent] = useState(false);
  const [renderedUrl, setRenderedUrl] = useState("");

  useEffect(() => {
    if (id) {
      if (isListening) {
        setRenderedUrl(selectedLesson?.audio_url || preview?.audio_preview_url);
      } else {
        setRenderedUrl(selectedLesson?.video_url || preview?.video_preview_url);
      }
    }
  }, [id, preview?.video_preview, selectedLesson?.video, isListening]);

  const thumbnailImg = selectedLesson?.thumb_nail_img || preview?.thumb_nail;

  useEffect(() => {
    if (renderedUrl && (!isListening ? thumbnailImg : AudioImg)) {
      const timer = setTimeout(() => {
        setShouldRenderContent(true);
      }, 100);

      return () => clearTimeout(timer);
    } else {
      setShouldRenderContent(false);
    }
  }, [renderedUrl, isListening, thumbnailImg, AudioImg]);

  //end: loading delay rendering the component until image is ready

  const handleListenClicked = () => {
    setFirstTimeLoaded(false)
    setIsListening(true);
    setIsWatching(false);
    setPlayTriggered(true);
    setShowButtons(false); // Hide buttons
    if (player.current) {
      if (isMediaReady) {
        player.current.play();
        if (setPlayIcon) setPlayIcon(true);
      } else {
        player.current.addEventListener(
          "canplay",
          () => player.current.play(),
          { once: true }
        );
      }
    }
  };

  const handleWatchClicked = () => {
    setFirstTimeLoaded(false)
    setIsWatching(true)
    setIsListening(false);
    setIsListening(false);
    setPlayTriggered(true);
    setShowButtons(false);
    if (player.current) {
      if (isMediaReady) {
        player.current.play();
        if (setPlayIcon) setPlayIcon(true);
      } else {
        player.current.addEventListener(
          "canplay",
          () => player.current.play(),
          { once: true }
        );
      }
    }
  };

  const [references, setReferences] = useState([]);

  const [popupData, setPopupData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const popupEndTimeRef = useRef(null);

  useEffect(() => {
    setReferences(
      selectedLesson?.course_references || preview?.course_references || []
    );

    const matchingReference = references.filter((ref) => {
      const referenceTime = ref.timestamp;
      return currentTime >= referenceTime && currentTime < referenceTime + 1;
    });

    if (matchingReference) {
      console.log("matchingReference heeeere", matchingReference);
      setPopupData(matchingReference);
      setOpenPopup(true);
      popupEndTimeRef.current = Math.floor(currentTime) + 4;
    }
  }, [currentTime, selectedLesson, preview]);

  useEffect(() => {
    if (popupData && popupData.length > 0) {
      let shouldOpenPopup = false;

      popupData.forEach((popupData) => {
        const referenceTime = popupData.timestamp;

        if (
          currentTime >= referenceTime &&
          currentTime < popupEndTimeRef.current
        ) {
          shouldOpenPopup = true;
        }
      });

      if (shouldOpenPopup) {
        setOpenPopup(true);
      } else {
        setOpenPopup(false);
      }
    } else {
      setOpenPopup(false);
    }
  }, [currentTime, popupData]);

  const renderPopupComponent = (data) => (
    <Box
      width="275px"
      bgcolor="rgba(2, 102, 112, 0.8)"
      color="#fff"
      borderRadius={3}
      overflow="visible"
      position="relative"
      height="auto"
    >
      <Card
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "hidden",
          borderRadius: "3 3 0 0",
        }}
      >
        {data?.image && (
          <CardMedia
            component="img"
            height="140"
            image={data.image}
            alt={data.description}
            sx={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "inherit",
            }}
          />
        )}
        <CardContent
          sx={{ padding: "8px 16px", textAlign: "left", overflow: "hidden" }}
        >
          <div>
            {data?.go_to_summary && (
              <Button
                size="small"
                target="_blank"
                sx={{
                  backgroundColor: "#fce181",
                  color: "#026670",
                  padding: "3px 10px",
                  position: "absolute",
                  top: data?.image ? 128 : -10,
                  right: 20,
                  overflow: "hidden",
                  "&:hover": {
                    backgroundColor: "#fce181",
                    color: "#026670",
                  },
                }}
              >
                Course Resources
              </Button>
            )}
            {data?.description && (
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 400,
                  marginTop: "15px",
                  padding: "0 10px",
                }}
              >
                {data.description}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );

  useEffect(() => {
    setShowButtons(true);
  }, [selectedLesson?.id]);

  //for selecting a bookmark inside the same lecture
  const [bookmarkToastOpen, setBookmarkToastOpen] = useState(false);
  const [bookmarkMessage, setBookmarkMessage] = useState("");
  useEffect(() => {
    if (bookmarkStartTime !== null && player.current) {
      player.current.currentTime = bookmarkStartTime;
      setBookmarkStartTime(null);
    }
  }, [bookmarkStartTime]);

  //time

  //remind me at
  const [remindMeAt, setRemindMeAt] = useState(false);

  // notes
  const [noteToastOpen, setNoteToastOpen] = useState(false);

   useEffect(()=>{
    // ("isListeninglog",isListening)
    console.log(`isListening: ${isListening}, renderedUrl: ${renderedUrl}`)

  },[isListening,renderedUrl])


  useEffect(()=>{
    console.log(`firstTimeLoaded: ${firstTimeLoaded}, isListening: ${isListening}, isWatching: ${isWatching}`)

  },[isListening,isWatching,firstTimeLoaded])




  return (
    <>
      <Toast
        open={remindMeAt}
        onClose={() => setRemindMeAt(false)}
        message="You will receive an email notification when the lecture becomes available."
      />
      <Box
        position="relative"
        sx={{
          ...(shouldRenderContent && {
            "&::before": {
              content: { xs: "none", md: '""' }, // Use double quotes for empty content
              position: "absolute",
              bottom: !modalScreen ? pxToRem(-40) : "auto",
              left: !modalScreen ? pxToRem(-40) : "auto",
              top: modalScreen ? pxToRem(-25) : "auto",
              right: modalScreen ? pxToRem(-25) : "auto",
              width: "46.6%",
              height: "59.7%",
              bgcolor: "#FCE181",
              borderRadius: pxToRem(20),
              zIndex: modalScreen ? "0" : "-1",
            },
          }),
          ...(modalScreen && {
            mx: { xs: pxToRem(-25), ssm: 0 },
          }),
        }}
      >
        <Box
          ref={playerWrapper}
          className="player-bgr"
          width="100%"
          height="100%"
          position="relative"
          display="flex"
          borderRadius={{ md: pxToRem(23) }}
          pb={shouldRenderContent ? "56.2%" : undefined}
          sx={{
            backgroundColor: shouldRenderContent ? "#000000" : "transparent",
            "&:hover": { "& .toggle-tooltip": { opacity: 1 } },
          }}
        >
          {/* // media player start here */}
          {shouldRenderContent ? (
            <Box pt={!modalScreen ? { xs: "60px", sssm: "65px", ssm: 0 } : 0}>
              <MediaPlayer
                style={{
                  ...style,
                  top: mobile_ssm ? 0 : "auto",
                  bottom: mobile_ssm ? "auto" : isFixed ? y * -1 : 0,
                  right: isFixed && !mobile_ssm ? x * -1 : 0,
                  borderRadius: pxToRem(23),
                }}
                {...listeners}
                {...attributes}
                ref={(node) => {
                  setNodeRef(node);
                  player.current = node;
                }}
                className={`${isFixed && !mobile_ssm ? "pip" : ""}`}
                // src={isListening ? audioSrc : videoSrc}
                src={renderedUrl}
                // poster={""} //fixthis
                poster={showButtons && firstTimeLoaded ? (isListening ? "" : thumbnailImg) : ""} //fixthis
                aspectRatio={16 / 9}
                crossorigin=""
                onPause={onPause}
                onPlay={onPlay}
                onProviderSetup={onProviderSetup}
                onEnded={() => {
                  setVideoEnded(true);
                  const overlayElement = document.querySelector(".end-overlay");
                  if (overlayElement) {
                    overlayElement.style.display = "flex";
                  }
                }}
                onTimeUpdate={(event) => {
                  currentTimeRef.current = event.detail.currentTime;
                  setCurrentTime(event.detail.currentTime);
                  console.log("Current time:", event.detail.currentTime);
                }}
                onSeeking={(event) => {
                  const currentTime = player.current.currentTime;
                  const seekTime = event.detail.time;

                  currentTimeRef.current = currentTime;

                  setCurrentTime(currentTime);

                  if (seekTime > currentTime) {
                    event.preventDefault();
                    player.current.currentTime = currentTime;
                  }
                }}
                autoplay={firstTimeLoaded?false:true}
                onCanPlay={() => {
                  if (bookmarkStartTime) {
                    player.current.currentTime = bookmarkStartTime;
                  } else if (startTime) {
                    player.current.currentTime = startTime;
                  }
                  setIsMediaReady(true);
                }}
              >
                {isListening && (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#00383E",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "inherit",
                    }}
                  >
                    <img
                      src={AudioImg}
                      alt="Audio thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    />
                  </Box>
                )}
                <MediaOutlet>
                  {!tablet && (
                    <MediaGesture event="pointerup" action="toggle:paused" />
                  )}
                  <MediaPoster alt="" />
                </MediaOutlet>

                <Box className="pip-overlay">
                  <IconButton
                    className="pip-close"
                    onClick={() => setIsFixed(false)}
                  >
                    <Close
                      color="#fff"
                      width={pxToRem(10)}
                      height={pxToRem(10)}
                    />
                  </IconButton>
                  <Button
                    className="pip-back-btn"
                    endIcon={
                      <NewWindow width={pxToRem(20)} height={pxToRem(20)} />
                    }
                    onClick={() => {
                      window.scrollTo({
                        top: scrollTargetPosition - pxToRem(68),
                        behavior: "smooth",
                      });
                    }}
                    sx={{
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: pxToRem(12),
                      fontWeight: 400,
                      p: `${pxToRem(2)} ${pxToRem(8)} ${pxToRem(2)} ${pxToRem(
                        12
                      )}`,
                      "&:hover": {
                        color: "#FCE181",
                        backgroundColor: "#000",
                      },
                    }}
                  >
                    Back to tab
                  </Button>
                  <MediaPlayButton />
                </Box>

                {fullscreen ? (
                  <Box
                    className="media-controls-group"
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                  >
                    <Box
                      width="100%"
                      position="absolute"
                      top={0}
                      height={pxToRem(68)}
                      pointerEvents="none"
                      pb={pxToRem(160)}
                      zIndex={25}
                      sx={{
                        background:
                          "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEmCAYAAACjy/qzAAAAhklEQVQ4y52RUQ6AMAxCKd7/JJ7R6aeJAdr507TjQZqOAE4CWARwx7JE944rch/k6qOWS7bq5bh72zGF8+LTa6goJeRPgXDYNxWFfkS0QXCInLIN1GxTXA0dtS0otWJnuXJR1Y9WYP9GF1UCYRQYHRSlXMARbVO4th3sd7Y3OP5dY3Bn+SkPsGJ1+HGGVtcAAAAASUVORK5CYII=)",
                        backgroundRepeat: "repeat-x",
                        backgroundPosition: "top",
                      }}
                    ></Box>
                    <Box
                      width="100%"
                      position="absolute"
                      left={pxToRem(18)}
                      top={pxToRem(18)}
                      pr={pxToRem(145)}
                      zIndex={26}
                    >
                      <Typography
                        color="#fff"
                        fontSize={pxToRem(27)}
                        sx={{
                          display: "block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          wordWrap: "normal",
                          cursor: "default",
                          textShadow: "0 0 2px rgba(0,0,0,.5)",
                        }}
                      >
                        {selectedLesson ? selectedLesson.name : preview.name}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                <Box
                  position="absolute"
                  top="40%"
                  left="50%"
                  display="flex"
                  sx={{
                    transform: "translate(-50%)",
                    pointerEvents: "none",
                  }}
                >
                  {playTriggered ? (
                    <div className="animWrapper">
                      <div className="play" />
                    </div>
                  ) : null}
                  {pauseTriggered ? (
                    <div className="animWrapper">
                      <div className="pause" />
                    </div>
                  ) : null}
                </Box>
                <Stack
                  className="media-controls-group"
                  flexDirection="row"
                  columnGap="47px"
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    display: { ssm: "none" },
                  }}
                >
                  <MediaSeekButton
                    seconds={-10}
                    style={{ width: "50px", height: "50px" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "all 0.3s ease-in",
                        ":hover": {
                          "::before": {
                            filter: "brightness(50)",
                          },
                        },
                        "::before": {
                          content: '""',
                          position: "absolute",
                          backgroundColor: "#FFFFFF80",
                          boxShadow: "0px 10px 20px #0000001A",
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                          backdropFilter: "blur(10px)",
                          filter: "brightness(2.5)",
                          zIndex: 0,
                        },
                      }}
                    >
                      {/* Centered Text */}
                      <Typography
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          color: "#026670 !important",
                          fontSize: "9px",
                          fontWeight: 600,
                          zIndex: 1,
                        }}
                      >
                        10
                      </Typography>

                      {/* Content Layer (Reverse Component) */}
                      <Box
                        component={Reverse}
                        sx={{
                          position: "relative",
                          left: "-1.5px",
                          display: {
                            xs: "block !important",
                            ssm: "none !important",
                          },
                          width: "32px",
                          height: "31px",
                          zIndex: 1,
                        }}
                        slot="reverse"
                      />
                    </Box>
                  </MediaSeekButton>
                  <MediaPlayButton>
                    <Box
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        ":hover svg": { color: "#026670" },
                        transform: "all 0.3s ease-in",
                        "::before": {
                          content: '""',
                          position: "absolute",
                          backgroundColor: "#FFFFFF80",
                          boxShadow: "0px 10px 20px #0000001A",
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                          backdropFilter: "blur(10px)",
                          filter: "brightness(2.5)",
                          zIndex: 0,
                        },
                        "::after": {
                          content: '""',
                          zIndex: -1,
                          position: "absolute",
                          borderRadius: "50%",
                          animation: "pulse 3s ease-out infinite",
                          width: "70px",
                          height: "70px",
                          backgroundColor: "#FFFFFF80",
                          backdropFilter: "blur(10px)",
                          "@keyframes pulse": {
                            "0%": {
                              transform: "scaleX(1)",
                              WebkitTransform: "scaleX(1)",
                            },
                            "50%": {
                              transform: "scale3d(1.05, 1.05, 1.05)",
                              WebkitTransform: "scale3d(1.05, 1.05, 1.05)",
                            },
                            "100%": {
                              transform: "scaleX(1)",
                              WebkitTransform: "scaleX(1)",
                            },
                          },
                        },
                      }}
                    >
                      <Box
                        component={Play}
                        sx={{
                          position: "relative",
                          right: "-1.5px",
                          color: "#026670",
                          display: { ssm: "none" },
                          width: "16px",
                          height: "19px",
                          zIndex: 1,
                        }}
                        slot="play"
                      />
                      <Box
                        component={Pause}
                        sx={{
                          position: "relative",
                          right: "-1.5px",
                          color: "#026670",
                          display: { ssm: "none" },
                          width: "16px",
                          height: "19px",
                          zIndex: 1,
                        }}
                        slot="pause"
                      />
                    </Box>
                  </MediaPlayButton>
                  <MediaSeekButton
                    seconds={10}
                    style={{ width: "50px", height: "50px" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "all 0.3s ease-in",
                        ":hover": {
                          "::before": {
                            filter: "brightness(50)",
                          },
                        },
                        "::before": {
                          content: '""',
                          position: "absolute",
                          backgroundColor: "#FFFFFF80",
                          boxShadow: "0px 10px 20px #0000001A",
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                          backdropFilter: "blur(10px)",
                          filter: "brightness(2.5)",
                          zIndex: 0,
                        },
                      }}
                    >
                      {/* Centered Text */}
                      <Typography
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          color: "#026670 !important",
                          fontSize: "9px",
                          fontWeight: 600,
                          zIndex: 1,
                        }}
                      >
                        10
                      </Typography>

                      {/* Content Layer (Forward Component) */}
                      <Box
                        component={Forward}
                        sx={{
                          position: "relative",
                          right: "-1.5px",
                          display: {
                            xs: "block !important",
                            ssm: "none !important",
                          },
                          width: "32px",
                          height: "31px",
                          zIndex: 1,
                        }}
                        slot="forward"
                      />
                    </Box>
                  </MediaSeekButton>
                </Stack>
                {showButtons && firstTimeLoaded &&(
                  <Stack
                    className="play-btn"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={pxToRem(20)}
                    width="100%"
                    height="100%"
                    position="absolute"
                    top="50%"
                    left="50%"
                    flexShrink={0}
                    sx={{
                      transform: "translate(-50%,-50%)",
                      "& media-play-button": { width: "auto" },
                    }}
                  >
                    <MediaPlayButton>
                      <Button
                        variant="yellow"
                        startIcon={<Play />}
                        sx={{
                          height: { xs: "44px", ssm: pxToRem(54) },
                          px: { xs: pxToRem(30), ssm: pxToRem(40) },
                          "&:hover svg": { color: "#026670" },
                        }}
                        // onClick={() => {
                        //   setPlayTriggered(true);
                        //   // setPlayIcon(true);
                        //   if (setPlayIcon) setPlayIcon(true);
                        // }}
                        onClick={handleWatchClicked}
                      >
                        {!noListen ? "Watch" : "Watch Preview"}
                        {/* Watch */}
                      </Button>
                    </MediaPlayButton>

                    {!noListen && (
                      <MediaPlayButton>
                        <Button
                          variant="yellow"
                          startIcon={<Volume />}
                          sx={{
                            height: { xs: "44px", ssm: pxToRem(54) },
                            px: { xs: pxToRem(30), ssm: pxToRem(40) },
                            "&:hover svg": { color: "#026670" },
                          }}
                          onClick={() => handleListenClicked()}
                        >
                          Listen
                        </Button>
                      </MediaPlayButton>
                    )}
                  </Stack>
                )}
                {selectedLesson && (
                  <SidePanel
                    selectedLesson={selectedLesson}
                    currentTime={currentTime}
                    setToastOpen={setToastOpen}
                    isListening={isListening}
                    handleListenClicked={handleListenClicked}
                    setIsListening={setIsListening}
                    handleWatchClicked={handleWatchClicked}
                    setBookmarkToastOpen={setBookmarkToastOpen}
                    setBookmarkMessage={setBookmarkMessage}
                    setBookmarkListChanged={setBookmarkListChanged}
                    setNoteListChanged={setNoteListChanged}
                    setNoteToastOpen={setNoteToastOpen}
                  />
                )}
                <Stack
                  className="media-controls-group"
                  height={pxToRem(mediaControlsHeight)}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={{ xs: pxToRem(13), ssm: pxToRem(15), sm: pxToRem(40) }}
                  position="absolute"
                  bottom={{
                    xs: 0,
                    sssm: pxToRem(10),
                    ssm: pxToRem(30),
                    sm: pxToRem(40),
                  }}
                  left={pxToRem(40)}
                  right={pxToRem(40)}
                  borderRadius={pxToRem(32)}
                  boxShadow={{
                    ssm: `0px ${pxToRem(10)} ${pxToRem(20)} rgba(0,0,0,0.16)`,
                  }}
                  backgroundColor={{ ssm: "rgba(255, 255, 255, 0.5)" }}
                  px={{ xs: 0, ssm: pxToRem(20), sm: pxToRem(40) }}
                  sx={{
                    backdropFilter: { xs: "unset", ssm: "blur(15px)" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(12),
                      fontWeight: "bold",
                      color: { xs: "#FCE181", ssm: "#026670" },
                      display: { xs: "flex", ssm: "none" },
                      gap: pxToRem(4),
                      "& span": {
                        fontFamily: "Roboto Mono",
                      },
                    }}
                  >
                    <MediaTime type="current" /> <span>/</span>{" "}
                    <MediaTime type="duration" />
                  </Typography>
                  {/* This */}
                  <Stack
                    className="hide-in-mobile"
                    flexDirection="row"
                    alignItems="center"
                    gap={{ xs: pxToRem(17), ssm: pxToRem(10), sm: pxToRem(17) }}
                  >
                    <MediaSeekButton seconds={-10}>
                      <MediaTooltip position="top center">
                        <span>Seek -10s</span>
                      </MediaTooltip>
                      <Typography
                        fontSize={pxToRem(12)}
                        fontWeight="bold"
                        color="secondary"
                      >
                        - 10 sec
                      </Typography>
                    </MediaSeekButton>
                    {preview ? null : (
                      <Box className="tooltip-hover">
                        <MediaTooltip position="top center">
                          <span>Prev</span>
                        </MediaTooltip>
                        <IconButtonStyled
                          sx={{
                            width: pxToRem(21),
                            "& svg": { transform: "rotate(180deg)" },
                          }}
                          onClick={() => {
                            handlePrevClick();
                          }}
                        >
                          <Next color="#026670" />
                        </IconButtonStyled>
                      </Box>
                    )}
                    <MediaPlayButton>
                      <MediaTooltip position="top center">
                        <span slot="play">Play</span>
                        <span slot="pause">Pause</span>
                        <span slot="replay">Replay</span>
                      </MediaTooltip>
                      <Play slot="play" color="#026670" />
                      <Pause slot="pause" color="#026670" />
                      <Play slot="replay" color="#026670" />
                    </MediaPlayButton>
                    {preview ? null : (
                      <Box className="tooltip-hover">
                        <MediaTooltip position="top center">
                          <span>Next</span>
                        </MediaTooltip>
                        <IconButtonStyled
                          sx={{ width: pxToRem(21) }}
                          onClick={() => {
                            handleNextClick();
                          }}
                        >
                          <Next color="#026670" />
                        </IconButtonStyled>
                      </Box>
                    )}
                    <MediaSeekButton seconds={+10}>
                      <MediaTooltip position="top center">
                        <span>Seek +10s</span>
                      </MediaTooltip>
                      <Typography
                        fontSize={pxToRem(12)}
                        fontWeight="bold"
                        color="secondary"
                      >
                        + 10 sec
                      </Typography>
                    </MediaSeekButton>
                  </Stack>
                  <MediaTimeSlider>
                    <div slot="preview">
                      <MediaSliderValue type="pointer" format="time" />
                    </div>
                  </MediaTimeSlider>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flexShrink={0}
                  >
                    <Typography
                      sx={{
                        fontSize: pxToRem(12),
                        fontWeight: "bold",
                        color: { xs: "#FCE181", ssm: "#026670" },
                        display: { xs: "none", ssm: "flex" },
                        gap: pxToRem(4),
                        marginRight: { ssm: pxToRem(20), sm: pxToRem(30) },
                        "& span": {
                          fontFamily: "Roboto Mono",
                        },
                      }}
                    >
                      <MediaTime type="current" /> <span>/</span>{" "}
                      <MediaTime type="duration" />
                    </Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={{
                        xs: pxToRem(20),
                        ssm: pxToRem(17),
                        sm: pxToRem(20),
                      }}
                    >
                      <IconButtonStyled
                        className="hide-in-mobile"
                        sx={{
                          "& svg": {
                            width: pxToRem(22),
                          },
                          "&:hover": {
                            "& svg": {
                              display: "none",
                            },
                            "& media-volume-slider": {
                              display: "block",
                            },
                          },
                        }}
                      >
                        <Volume color="#026670" />
                        <MediaVolumeSlider>
                          <MediaSliderValue
                            type="pointer"
                            format="percent"
                            slot="preview"
                          />
                        </MediaVolumeSlider>
                      </IconButtonStyled>
                      <Box
                        component={Setting}
                        cursor="pointer"
                        sx={{
                          color: {
                            xs: "#FCE181",
                            ssm: "#026670",
                          },
                          display: { ssm: "none" },
                          width: pxToRem(18.5),
                          ":hover": { color: "#026670" },
                        }}
                        slot="exit"
                      />
                      <MediaFullscreenButton>
                        <MediaTooltip position="top center">
                          <span slot="enter">Enter Fullscreen</span>
                          <span slot="exit">Exit Fullscreen</span>
                        </MediaTooltip>
                        <Box
                          component={Fullscreen}
                          sx={{
                            color: {
                              xs: "#FCE181",
                              ssm: "#026670",
                            },
                            ":hover": { color: "#026670" },
                          }}
                          slot="enter"
                        />
                        <Box
                          component={FullscreenExit}
                          sx={{
                            color: {
                              xs: "#FCE181",
                              ssm: "#026670",
                            },
                            ":hover": { color: "#026670" },
                          }}
                          slot="exit"
                        />
                      </MediaFullscreenButton>
                      <MediaMenu className="hide-in-mobile">
                        <MediaMenuButton aria-label="Settings">
                          <Settings color="#026670" />
                          <MediaTooltip>
                            <span slot="open">Open Settings</span>
                            <span slot="close">Close Settings</span>
                          </MediaTooltip>
                        </MediaMenuButton>
                        <MediaMenuItems>
                          <MediaMenu>
                            <MediaPlaybackRateMenuButton label="Speed"></MediaPlaybackRateMenuButton>
                            <MediaPlaybackRateMenuItems
                              rates={[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]}
                              normalLabel="Normal"
                            />
                          </MediaMenu>
                          <MediaMenu>
                            <MediaQualityMenuButton label="Quality"></MediaQualityMenuButton>
                            <MediaQualityMenuItems autoLabel="Auto" />
                          </MediaMenu>
                        </MediaMenuItems>
                      </MediaMenu>
                    </Stack>
                  </Stack>
                </Stack>

                <EndView
                  isPreview={selectedLesson == undefined}
                  isSubscribed={preview.subscription_status === "Active"}
                  content={selectedLesson ? selectedLesson : preview}
                  setPlayIcon={setPlayIcon || (() => {})}
                  setPlayTriggered={setPlayTriggered}
                  setNextLecture={setNextLecture}
                  nextLecture={nextLecture}
                  lastLesson={lastLesson}
                  setIsListening={setIsListening}
                  setIsFixed={setIsFixed}
                  isFixed={isFixed}
                  handleNextLectureClick={handleNextLectureClick}
                  lessons={lessons}
                  selectedLesson={selectedLesson}
                  videoEnded={videoEnded}
                  setVideoEnded={setVideoEnded}
                  hoursRestriction={preview?.next_lesson_availability}
                  setBlockAutoLectureId={setBlockAutoLectureId}
                  setRemindMeAt={setRemindMeAt}
                />
              </MediaPlayer>
            </Box>
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              sx={{
                borderRadius: { md: pxToRem(20) },
                height: "0",
                paddingBottom: "50%",
              }}
            />
          )}

          {openPopup && (
            <div
              style={{
                width: "85%",
                position: "absolute",
                display: "flex",
                margin: "auto",
                top: "50%",
                left: "25%",
                transform: "translate(-25%,-50%)",
                padding: "20px 0",
              }}
            >
              {openPopup &&
                popupData.map((data, index) => (
                  <div
                    key={index}
                    style={{ padding: "10px 0", marginRight: "40px" }}
                  >
                    {data.go_to_summary ? (
                      <div
                        onClick={openResourcesTab}
                        style={{
                          cursor: "pointer",
                          zIndex: 10,
                        }}
                      >
                        {renderPopupComponent(data)}
                      </div>
                    ) : data.link ? (
                      <MuiLink
                        href={data.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "white",
                          textDecoration: "none",
                          marginTop: "5px",
                          zIndex: 10,
                        }}
                      >
                        {renderPopupComponent(data)}
                      </MuiLink>
                    ) : (
                      <div
                        style={{
                          cursor: "pointer",
                          zIndex: 10,
                        }}
                      >
                        {renderPopupComponent(data)}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </Box>
        <Toast
          open={toastOpen}
          onClose={() => setToastOpen(false)}
          message="Link copied to clipboard!"
        />
        <Toast
          open={bookmarkToastOpen}
          onClose={() => {
            setBookmarkToastOpen(false);
            setBookmarkMessage("");
          }}
          message={bookmarkMessage}
        />

        <Toast
          open={noteToastOpen}
          onClose={() => setNoteToastOpen(false)}
          message="Note saved successfully!"
        />
      </Box>
    </>
  );
}
