import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import Category from "./Category";
import Course from "./Course";
import { formatDate, formatDuration } from "../../utils/format";
import { SwiperSlide } from "swiper/react";
import Slider from "../../components/Slider";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { styled } from "@mui/system";
import shouldNavigateToPresubscribe from "../../utils/subscription";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#d6d6d6",
  color: "#333333 ",
  fontSize: "12px", // Smaller font size for the button text
  padding: "4px 8px", // Smaller padding
  "&:hover": {
    backgroundColor: "#c4c4c4",
  },
  borderRadius: "10px",
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  width: "18px",
  height: "18px",
  pading: "15px 8px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: "#f3f3f3",
    color: "#333333",
    borderRadius: "50%",
    padding: "2px", // Adjust padding as needed
    fontSize: "16px", // Smaller icon size
  },
  "&:hover .MuiSvgIcon-root": {
    backgroundColor: "#e0e0e0",
  },
}));

const CategoryFilterButton = ({ selectedCategory, categoryName, onClear }) => {
  return (
    selectedCategory && (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {" "}
        {/* Smaller gap */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FilterAltIcon sx={{ color: "#bfbebb", fontSize: "20px" }} />{" "}
          {/* Smaller icon size */}
          <Typography
            sx={{ color: "#bfbebb", fontSize: "12px", fontWeight: 500 }}
          >
            Filters:
          </Typography>
        </Box>
        <StyledButton
          endIcon={
            <CustomIconButton size="small" onClick={onClear}>
              <CloseIcon />
            </CustomIconButton>
          }
        >
          {categoryName}
        </StyledButton>
      </Box>
    )
  );
};

const CoursesSection = ({
  courses,
  setCourses,
  setPage,
  onFavoriteClick,
  isAuthenticated,
  lastCourseElementRef,
  loading,
  courseCount,
  isOnSearchMode,

  categoriesList,
  selectedCategory,
  setSelectedCategory,
  

}) => {
  const categories = courses.reduce((acc, course) => {
    const categoryName = course?.category?.name;
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(course);
    return acc;
  }, {});

  const totalVideos = courses.length;

  //for ref
  const flatCourses = Object.values(categories).flat();
  const lastCourseId = flatCourses[flatCourses.length - 1]?.id;

  const [fetchedCourseIds, setFetchedCourseIds] = useState(new Set());

  // Update fetched courses set when new courses are added : CHECK THIS
  useEffect(() => {
    setFetchedCourseIds((prevIds) => {
      const newIds = new Set(prevIds);
      flatCourses.forEach((course) => newIds.add(course.id));
      return newIds;
    });
  }, [flatCourses]);

  const displayedCount = isOnSearchMode ? courses.length : courseCount;
  const displayedLabel = displayedCount === 1 ? "Course" : "Courses";

  // filter
  const categoryName = categoriesList.find(
    (category) => category.id === selectedCategory
  )?.name;

  const handleClear = () => {
    setSelectedCategory("");
    setCourses([])
    setPage(1)
  };

  useEffect(() => {
    console.log("displayedCount", !displayedCount);
  }, [displayedCount]);
  return (
    <Box mt="60px">
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="15px"
        mb="25px"
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography
            variant="sectionTitle"
            component="h2"
            fontSize="35px"
            mb={0}
          >
            <span className="highlighted">Courses</span>
          </Typography>
          {selectedCategory && (
            <CategoryFilterButton
              selectedCategory={selectedCategory}
              categoryName={categoryName}
              onClear={handleClear}
              
            />
          )}
        </Box>
        <Typography fontSize="12px" fontWeight={600} color="#BFBEBB">
          {loading ? "" : `${displayedCount} ${displayedLabel}`}
        </Typography>
      </Stack>

      <Stack gap="40px" sx={{ width: "100%" }}>
        {Object.keys(categories).map((category, index) => (
          <Category key={index} title={category}>
            {categories[category].map((course, courseIndex) => {
              const isLastCourse = course.id === lastCourseId;

              const linkUrl = shouldNavigateToPresubscribe(course.subscription_status)
              ? `/course/${course.slug}/presubscribe`
              : `/course/${course.slug}`;


              return (
                <SwiperSlide key={course.id}>
                  <Course
                    // passRef={isLastCourse ? lastCourseElementRef : null}
                    passRef={
                      isOnSearchMode
                        ? null
                        : isLastCourse
                        ? lastCourseElementRef
                        : null
                    }
                    image={course?.thumb_nail}

                    title={course?.name}
                    isTitleRtl={course?.is_name_rtl}
                    subtitle={course?.subtitle}
                    isSubtitleRtl={course?.is_subtitle_rtl}

                    text={course?.description}
                    subscriptionStatus={course?.subscription_status}
                    linkUrl={linkUrl}
                    date={formatDate(course.release_date || course.updated_at)}
                    subscribers={course.subscriber_count}
                    duration={formatDuration(course.length)}
                    labels={course.labels.map((label) => label.name)}
                    onFavoriteClick={() => onFavoriteClick(course.id)}
                    course={course}
                    isAuthenticated={isAuthenticated}
                    // loading={loading}
                    loading={!fetchedCourseIds.has(course.id)}
                  />
                </SwiperSlide>
              );
            })}
          </Category>
        ))}
      </Stack>
    </Box>
  );
};

export default CoursesSection;
