import React from "react";
import { Link, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";

const LinkBtn = (props) => {
  const { title, footerLink, component, useFirstStyle = false, ...rest } = props;

  return (
    <Link
      {...rest}
      to={props.to}
      component={component ?? NavLink}
      underline="none"
      sx={{
        textDecoration: "none",
        color: footerLink ? "#BFBEBB" : "secondary.main",
        fontSize: pxToRem(16),
        fontWeight: 600,
        position: "relative",
        width: useFirstStyle ? "100%" : undefined,
        display: useFirstStyle ? "block" : undefined,
        "&:hover": {
          color: footerLink ? "#fff" : "primary.main",
        },
        ...(useFirstStyle
          ? {
              "&:hover span::after": {
                width: "100%",
              },
            }
          : {
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: pxToRem(-8),
                height: "2px",
                width: 0,
                bgcolor: footerLink ? "#fff" : "primary.main",
                transition: "0.2s ease",
              },
              "&:hover::before": {
                width: "100%",
              },
              "&.active": {
                color: footerLink ? "#fff" : "#333",
              },
            }),
      }}
    >
      {useFirstStyle ? (
        <Box
          component="span"
          sx={{
            position: "relative",
            display: "inline-block",
            color: "inherit",
            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              bottom: pxToRem(-2),
              height: "2px",
              width: "0%",
              backgroundColor: footerLink ? "#fff" : "primary.main",
              transition: "width 0.2s ease",
            },
          }}
        >
          {title}
        </Box>
      ) : (
        title
      )}
    </Link>
  );
};

export default LinkBtn;
