import { useEffect, useState, useRef } from "react";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Skeleton,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as Check } from "../../../images/check.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Lock } from "../../../images/lock.svg";
import Chevron from "../../../images/chevron.svg";
import TextLink from "../../../components/TextLink";
import { textAlign, width } from "@mui/system";
import { handleResolve, handleResubscribe } from "../../../utils/Enrollment";

const Sidebar = (props) => {
  const contentRef = useRef(null);
  const [courseOverviewIsOpen, setCourseOverviewIsOpen] = useState(false);

  const handleCourseOverviewClick = () => {
    setCourseOverviewIsOpen(!courseOverviewIsOpen);
  };

  const handleItemClick = (item) => {
    props.setBlockAutoLectureId(true);
    console.log("", item);
    if (
      item.status !== "unwatched" &&
      item.status !== "locked" &&
      item.status !== "unsubscribed" &&
      item.status !== "anonymous" &&
      item.status !== "expired" &&
      item.status !== "paused"
    ) {
      const updatedPreviousStates = { ...props.previousLessonStates };

      if (props.playingLessonId !== null) {
        const previousPlayingLesson = props.lessons.find(
          (lesson) => lesson.id === props.playingLessonId
        );
        if (previousPlayingLesson) {
          updatedPreviousStates[props.playingLessonId] =
            previousPlayingLesson.status;
        }
      }
      updatedPreviousStates[item.id] = item.status;
      props.setPreviousLessonStates(updatedPreviousStates);
      props.onLessonClick(item);

      // Update the status of the clicked lesson
      const updatedLessons = props.lessons.map((lesson) => {
        if (lesson.id === item.id) {
          return { ...lesson, status: "playing" };
        } else if (lesson.id === props.playingLessonId) {
          return {
            ...lesson,
            status: props.previousLessonStates[lesson.id] || "available",
          };
        } else {
          return lesson;
        }
      });

      // Update the currently playing lesson ID
      props.setPlayingLessonId(item.id);

      // Call the updateLessons function to update the lessons state in the parent component
      props.updateLessons(updatedLessons);
    }
  };
  const theme = useTheme();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const lessonLimit =
    !props.isCollapsable && tablet ? (mobile ? 4 : 6) : props.lessons.length;

  const previousScrollPosition = useRef(null);

  const handleReadMoreClick = (event) => {
    event.preventDefault();
    previousScrollPosition.current = window.scrollY;
    setShowFullDescription((prevState) => !prevState);
  };

  useEffect(() => {
    if (showFullDescription) {
      window.scrollBy(0, -(window.scrollY - previousScrollPosition.current));
      previousScrollPosition.current = null;
    }
  }, [showFullDescription]);




  const lessonsToDisplay =
    showFullDescription || props.isCollapsable || !tablet
      ? props.lessons
      : props.lessons.slice(0, lessonLimit);

  return (
    <Box
      width={{ xs: "100%", md: pxToRem(300) }}
      backgroundColor={{
        xs: !props.isCollapsable ? "#EDECE8" : "",
        ssm: "#EDECE8",
      }}
      pt={{
        xs: !props.isCollapsable ? pxToRem(40) : "",
        ssm: pxToRem(40),
        md: pxToRem(150),
      }}
      mt={{ md: pxToRem(-150) }}
      pl={{ xs: !props.isCollapsable ? pxToRem(40) : "", ssm: pxToRem(40) }}
      pb={{ xs: !props.isCollapsable ? pxToRem(40) : "", ssm: pxToRem(40) }}
      pr={{
        xs: !props.isCollapsable ? pxToRem(40) : "",
        ssm: pxToRem(40),
        md: pxToRem(20),
        lg: 0,
      }}
      borderRadius={{
        xs: !props.isCollapsable ? pxToRem(20) : "",
        ssm: pxToRem(40),
      }}
      flexShrink={0}
      order={{ xs: 1, md: 2 }}
      sx={{
        ...props.sx,
        "&::before": {
          content: { xs: "none", md: "''" },
          position: "absolute",
          bottom: 0,
          right: 0,
          left: `calc(54% + ${pxToRem(300)})`,
          height: "100%",
          backgroundColor: "#EDECE8",
          borderRadius: pxToRem(40),
          zIndex: -1,
        },
      }}
    >
      {(props.subscriptionStatus === "Expired" || props.subscriptionStatus === "Paused" )&&(
        <Box
          sx={{
            // backgroundColor: "#FF0000",
            // color:"ff4d4d",
            // border:"1px solid #ff4d4d",
            
            // position:"absolute",
            // top: "5%",
            // right:"10%" ,
            backgroundColor: "#ffeded",
            display: "flex",
            padding: "15px 20px",
            borderRadius: "10px",
            border: "1px solid red",
            marginTop: "-70px",
            marginBottom:{ xs:"0px",md:"17px"},
          }}
        >
          <Typography
            sx={{
              color: "#ff4d4d",
              fontSize: "14px",
              whiteSpace: "nowrap",
              fontWeight: 700,
            }}
          >
            {props.subscriptionStatus === "Expired" ? "Your subscription has expired." : "Your subscription is paused."}
         
          </Typography>


           <Button
            sx={{
              color: "#026670",
              fontSize: "14px",
              fontWeight: 500,
              "&:hover": { color: "#01373c" },
            }}
            onClick={
              props.subscriptionStatus === "Expired"
                ? () => handleResubscribe(props.courseId,"Normal", "Normal")
                : () => handleResolve(props?.subscriptionId)
            }
          >
            {props.subscriptionStatus === "Expired"
              ? "Resubscribe"
              : "Resolve"}
          </Button>
        </Box>
      )}
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: "pointer" }}
        onClick={props.isCollapsable ? handleCourseOverviewClick : undefined}
      >
        <Box>
          <Typography
            component="p"
            fontSize={pxToRem(20)}
            fontWeight="bold"
            lineHeight={pxToRem(30)}
          >
            Course Overview
          </Typography>
          {!props?.isPresubscribed && (
            <>
              {props.completedStatus ? (
                <Typography
                  component="p"
                  fontSize={pxToRem(12)}
                  fontWeight="700"
                  lineHeight={pxToRem(18)}
                  color="#026670"
                >
                  {props.completedStatus}
                </Typography>
              ) : (
                <Skeleton variant="text" width={80} height={18} />
              )}
            </>
          )}
        </Box>
        {props.isCollapsable && (
          <Box
            sx={{
              display: { xs: "none", ssm: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
              paddingRight: pxToRem(13),
            }}
          >
            <img
              src={Chevron}
              style={{
                transform: courseOverviewIsOpen
                  ? "rotate(-90deg)"
                  : "rotate(90deg)",
                transition: "all 0.3s ease-in-out",
                height: pxToRem(23.5),
              }}
              alt="Chevron"
            />
          </Box>
        )}
      </Stack>

      <Box
        ref={contentRef}
        maxHeight={
          props.isCollapsable
            ? {
                ssm: courseOverviewIsOpen
                  ? `${contentRef.current?.scrollHeight}px`
                  : "0px",
                md: "100%",
              }
            : "none"
        }
        overflow={{
          ssm: props.isCollapsable ? "hidden" : "visible",
          md: "visible",
        }}
        style={{ transition: "max-height 0.3s ease-in-out" }}
      >
        <Stack
          rowGap={pxToRem(30)}
          pt={pxToRem(30)}
          display={{ ssm: "grid", md: "flex" }}
          columnGap={pxToRem(48)}
          gridTemplateColumns={{ sm: "1fr 1fr" }}
        >
          {props.lessons && props.lessons.length > 0 ? (
            lessonsToDisplay.map((lesson, index) => {
              const isLocked =
                index > 0 && !props.lessons[index - 1].can_watch_next_lesson;
              return (
                <ListItem
                  key={lesson.id}
                  id={lesson.id}
                  name={lesson.name}
                  short_description={lesson.short_description}
                  duration={lesson.duration}
                  status={lesson.status}
                  hours_remaining={lesson.hours_remaining}
                  onClick={() => handleItemClick(lesson)}
                  isTooltip={isLocked}
                  isSubscribed={props.isSubscribed}
                  subscriptionStatus={props.subscriptionStatus}
                  playingLessonId={props.playingLessonId}
                  playIcon={props.playIcon}
                  isFailed={props?.isFailed}
                  isCollapsable={props.isCollapsable}
                />
              );
            })
          ) : (
            <Stack
              flexDirection="row"
              columnGap={{ xs: pxToRem(24), md: pxToRem(10) }}
              p={`0 0 ${pxToRem(30)} 0`}
              sx={{
                position: "relative",
                borderBottom: "1px solid rgba(191, 190, 187, 0.5)",
                backgroundColor: "transparent",
              }}
            >
              <Box
                position={{ md: "absolute" }}
                left={pxToRem(-56)}
                top={pxToRem(13)}
                width={pxToRem(32)}
                height={pxToRem(32)}
                borderRadius="100%"
                flexShrink={0}
                // bgcolor="#fff"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Skeleton
                  variant="circular"
                  width={pxToRem(44)}
                  height={pxToRem(33)}
                />
              </Box>

              <Box component="span" textAlign="left">
                <Skeleton
                  variant="text"
                  width={pxToRem(100)}
                  height={pxToRem(24)}
                />

                <Skeleton
                  variant="text"
                  width={pxToRem(160)}
                  height={pxToRem(16)}
                />

                <Skeleton
                  variant="text"
                  width={pxToRem(150)}
                  height={pxToRem(16)}
                />
              </Box>

              <Typography marginLeft="auto">
                <Skeleton
                  variant="text"
                  width={pxToRem(50)}
                  height={pxToRem(24)}
                  marginLeft="auto"
                />
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
      {tablet && !props.isCollapsable && props.lessons.length > lessonLimit && (
        <TextLink
          href=""
          onClick={handleReadMoreClick}
          showFullDescription={showFullDescription}
          sx={{
            "& span": { fontSize: pxToRem(16), marginRight: pxToRem(5) },
            width: "100%",
            mt: pxToRem(33),
          }}
        >
          {showFullDescription ? "View Less Lectures" : "View More Lectures"}
        </TextLink>
      )}
    </Box>
  );
};

const ListItem = (props) => {
  // console.log("listitemprops",props.playIcon)
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (
      props.status === "locked" ||
      props.status === "unwatched" ||
      props.status === "unsubscribed" ||
      props.status === "anonymous" ||
      props.status === "expired" ||
      props.status === "paused" ||
      props?.subscriptionStatus === "Deleted" ||
      props?.subscriptionStatus === "Inactive" ||
      props?.subscriptionStatus === "Failed"
    ) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStatusClass = () => {
    if (props.status === "playing" && props.id === props.playingLessonId) {
      return "active";
    }
    switch (props.status) {
      case "completed":
        return "completed";
      case "locked":
        return "locked";
      case "unwatched":
        return "unwatched";
      case "available":
        return "available";
      default:
        return "";
    }
  };

  const getTooltipTitle = () => {
    if (props.status === "locked") {
      const { hours, minutes } = props.hours_remaining;
      let timeString = "";

      if (hours > 0) {
        timeString += `${hours} hour${hours === 1 ? "" : "s"}`;
      }

      if (minutes > 0) {
        if (hours > 0) {
          timeString += " and ";
        }
        timeString += `${minutes} minute${minutes === 1 ? "" : "s"}`;
      }

      return `The lecture will be available in ${timeString}`;
    } else if (props.status === "unwatched") {
      return "Complete previous lectures to unlock";
    } else if (props.status === "unsubscribed") {
      return "Subscribe to access this lecture";
    } else if (props.status === "anonymous") {
      return "Log in to access this lecture";
    } else if (props.status === "paused" || props.status === "expired") {
      return "Your enrollment is not active";
    } else if (props?.subscriptionStatus === "Failed") {
      return "Your payment method has failed";
    } else if (props?.subscriptionStatus === "Deleted") {
      return "Subscribe to access this lecture";
    } else if (props?.subscriptionStatus === "Inactive") {
      return "Subscribe to access this lecture";
    } else {
      return "";
    }
  };

  // alert(props.subscriptionStatus)

  return (
    <Tooltip
      title={getTooltipTitle()}
      placement="top"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      enterDelay={0}
      enterTouchDelay={0}
      leaveTouchDelay={0}
      PopperProps={{ disablePortal: true }}
    >
      <Stack
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={() =>
          props.status !== "locked" &&
          props.status !== "unwatched" &&
          props.status !== "unsubscribed" &&
          props.status !== "anonymous" &&
          props.subscriptionStatus !== "Deleted" &&
          props.subscriptionStatus !== "Inactive" &&
          props.onClick()
        }
        className={getStatusClass()}
        component="button"
        flexDirection="row"
        columnGap={{ xs: pxToRem(24), md: pxToRem(10) }}
        sx={{
          position: "relative",
          border: "none",
          backgroundColor: "transparent",
          borderBottom: {
            xs: "none",
            md:
              props.id === props.playingLessonId
                ? "1px solid #333333"
                : "1px solid rgba(191, 190, 187, 0.5)",
          },
          "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
            mb: `3px !important`,
          },
          "&:hover .status-ic": {
            display: "flex",
          },
          "&.active, &.completed:hover": {
            borderColor: "#333",
            cursor: "pointer",
            "& span": {
              color: "#333",
            },
          },
          "&.locked svg:last-of-type": {
            display: "block",
          },
          "&.unwatched, &.unsubscribed, &.anonymous, &.expired, &.paused": {
            cursor: "not-allowed",
          },

          "&:hover": {
            "& .sb_short_description": {
              color: "#333333",
            },
            "& .sb_name": {
              color: "#333333",
            },
          },
        }}
      >
        <Box
          className="status-ic"
          position={{
            xs: props.isCollapsable ? "static" : "absolute",
            sm: "static",
            md: "absolute",
          }}
          left={pxToRem(-56)}
          top={pxToRem(13)}
          width={pxToRem(32)}
          height={pxToRem(32)}
          borderRadius="100%"
          bgcolor={
            props.id === props.playingLessonId
              ? props.playIcon
                ? "#FCE181"
                : "#fff"
              : "#fff"
          }
          flexShrink={0}
          sx={{
            display: "flex",
            cursor: "pointer",
          }}
          alignItems="center"
          justifyContent="center"
        >
          {props.id === props.playingLessonId ? (
            <Arrow color="#026670" style={{ marginLeft: pxToRem(3) }} />
          ) : props.status === "unwatched" ||
            props.status === "locked" ||
            props.status === "unsubscribed" ||
            props.status === "expired" ||
            props.status === "anonymous" ||
            props.status === "paused" ||
            props?.isFailed ||
            props?.subscriptionStatus === "Deleted" ||
            props?.subscriptionStatus === "Inactive" ? (
            <Lock />
          ) : props.status === "completed" ? (
            <Check color="#026670" />
          ) : props.status === "available" ? (
            <Check color="#EDECE8" />
          ) : null}
        </Box>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          height="100%"
          width="100%"
          p={`0 0 ${pxToRem(30)} 0`}
          borderBottom={{
            xs:
              props.id === props.playingLessonId
                ? "1px solid #333333"
                : "1px solid rgba(191, 190, 187, 0.5)",
            md: "none",
          }}
        >
          <Box component="span" textAlign="left" cursor="pointer" width="100%">
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              marginBottom={pxToRem(6)}
              gap={pxToRem(15)}
            >
              <Typography
                component="span"
                display="block"
                fontSize={pxToRem(16)}
                fontWeight={600}
                color={
                  props.id === props.playingLessonId ? "#333333" : "secondary"
                }
                sx={{ cursor: "pointer" }}
                className="sb_name"
              >
                {props.name}
              </Typography>
              <Typography
                component="span"
                fontSize={pxToRem(16)}
                fontWeight={600}
                color="primary"
                marginLeft="auto"
              >
                {props.duration}
              </Typography>
            </Stack>
            <Typography
              component="span"
              fontSize={pxToRem(14)}
              fontWeight={500}
              lineHeight={pxToRem(18)}
              sx={{ cursor: "pointer", wordBreak: "break-word" }}
              color={props.id === props.playingLessonId ? "#333333" : "#BFBEBB"}
              className="sb_short_description"
            >
              {props.short_description}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Tooltip>
  );
};

export default Sidebar;
