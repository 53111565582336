import api from "./api";
import { checkAuth } from "../utils/checkAuth";
import axios from "axios";
import { url } from "./config";

export async function getCourse(id) {
  if (checkAuth()) {
    const res = await api.get(`/api/courses/student/${id}/`);
    return res;
  } else {
    const res = await axios.get(`${url}/api/courses/student/${id}/`, {
      headers: null,
    });
    return res;
  }
}

export async function getCoursePreview(id) {
  if (checkAuth()) {
    const res = await api.get(`/api/courses/student/${id}/`);
    return res;
  } else {
    const res = await axios.get(`${url}/api/courses/student/${id}/`);
    return res;
  }
}


export async function listCourses(
  pageSize = 4,
  page = 1,
  search = "",
  category = "",
  isSubscribed = "",
  isFree = "",
  sortingOrder = "",
  sortingOption = "",
  releaseDateStart = "",
  releaseDateEnd = "",
) {

  if (checkAuth()) {
    const res = await api.get(
      `/api/courses/student/?page_size=${pageSize}&page=${page}&name=${search}&category=${category}&is_subscribed=${isSubscribed}&is_free=${isFree}&ordering=${
        sortingOrder + sortingOption
      }&release_date_after=${releaseDateStart}&release_date_before=${releaseDateEnd}`
    );
    return res;
  } else {
    const res = await axios.get(
      `${url}/api/courses/student/?page_size=${pageSize}&page=${page}&name=${search}&category=${category}&is_subscribed=${isSubscribed}&is_free=${isFree}&ordering=${
        sortingOrder + sortingOption
      }&release_date_after=${releaseDateStart}&release_date_before=${releaseDateEnd}`,
      {
        headers: null,
      }
    );
    return res;
  }
}



export async function listCategories(pageSize = 30, page = 1, search = "") {
  const res = await axios.get(`${url}/api/courses/categories/?page_size=${pageSize}&page=${page}&name=${search}`);
  return res;
}

export async function postComment(lessonId, comment) {
  const res = await api.post(`/api/courses/lessons/${lessonId}/`, comment);
  return res;
}

export async function postRating(lessonId, rating) {
  const res = await api.post(`/api/courses/lessons/${lessonId}/`, rating);
  return res;
}



export async function getCheckoutLink(courseId, priceType, priceClass) {
  const res = await api.get(
    `/api/subscription/get-checkout-link/?course_id=${courseId}&price_type=${priceType}&price_class=${priceClass}`
  );
  return res;
}



export async function updateProgress(payload) {
  if (checkAuth()) {
    const res = await api.post(`/api/courses/progress/`, payload);
    return res;
  }
}

export async function getPaymentHistory(pageSize, page) {
  const res = await api.get(
    `/api/subscription/payment-history/?page_size=${pageSize}&page=${page}`
  );
  return res;
}

export async function listReviews(courseId) {
  if (checkAuth()) {
    const res = await api.get(`/api/courses/${courseId}/ratings/`);
    return res;
  } else {
    const res = await axios.get(`${url}/api/courses/${courseId}/ratings/`, {
      headers: null,
    });
    return res;
  }
}

export async function addReview(courseId, payload) {
  const res = await api.post(`api/courses/${courseId}/ratings/`, payload);
  return res;
}

export async function addComment(courseId, payload) {
  const res = await api.post(`api/courses/${courseId}/comments/`, payload);
  return res;
}

export async function listComments(
  courseId,
  pageSize,
  page,
  sortingOrder,
  sortingOption
) {
  if (checkAuth()) {
    const res = await api.get(
      `api/courses/${courseId}/comments/?page_size=${pageSize}&page=${page}&ordering=${
        sortingOrder + sortingOption
      }`
    );
    return res;
  } else {
    const res = await axios.get(
      `${url}/api/courses/${courseId}/comments/?page_size=${pageSize}&page=${page}&ordering=${
        sortingOrder + sortingOption
      }`,
      {
        headers: null,
      }
    );
    return res;
  }
}

export async function likeComment(commentId, payload) {
  const res = await api.post(
    `api/courses/comments/${commentId}/like/`,
    payload
  );
  return res;
}

export async function deleteComment(commentId) {
  const res = await api.delete(`api/courses/comments/${commentId}/`);
  return res;
}

export async function listReplies(commentId) {
  if (checkAuth()) {
    const res = await api.get(`api/courses/comment/${commentId}/replies`);
    return res;
  } else {
    const res = await axios.get(
      `${url}/api/courses/comment/${commentId}/replies`
    );
    return res;
  }
}

export async function postBookmark(lessonId, timeWatched) {
  const payload = {};
  const res = await api.post(`api/courses/bookmarks/`, payload);
  return res;
}




export async function listBookmarks(id) {
  if (checkAuth()) {
    const res = await api.get(`api/courses/${id}/bookmarks/`);
    return res;
  }
}



export async function createBookmark(lessonId, timestamp) {
  if (checkAuth()) {
    // const res = await api.post(`/api/bookmarks/`, payload);
    const payload = { timestamp };
    const res = await api.post(`api/courses/bookmark/${lessonId}/`, payload);
    // const res = await api.post(`api/courses/${lessonId}/bookmarks/`, payload);
    
    return res;
  }
}

export async function deleteBookmark(bookmarkId) {

  if (checkAuth()) {
    const res = await api.delete(`api/courses/bookmarks/${bookmarkId}/`);
    return res;
  }
}


export const addFavorite = (courseId) => {
  return api.post(`/api/courses/${courseId}/favorite/`);
};

export const removeFavorite = (courseId) => {
  return api.post(`/api/courses/${courseId}/favorite/`);
};


export const subscribeToFreeCourse = async (courseId) => {
  try {
    return api.post('/api/subscription/free_subscribe/',{ course_id: courseId },);
  } catch (error) {
    console.error('Error subscribing to course:', error);
    throw error;
  }
};


// lesson 


export const getLessons = async (courseId) => {
  try {
    const res = await api.get(`/api/courses/${courseId}/lessons/`);
    return res;
  } catch (error) {
    console.error('Error getting lesson:', error);
    throw error;
  }
};


// download lesson files as zip 
export const downloadAllAsZip = async (lessonId) => {
  try {
    const res = await api.get(`api/courses/lessons/${lessonId}/download/`, {
      responseType: 'arraybuffer',
    });
    return res;
  } catch (error) {
    console.error('Error downloading ZIP:', error);
  }
};

export const remindMeAt = async (lessonId,time) => {
 
  try {
    const res = await api.post(`api/courses/remindme_later/${lessonId}/ `);
   
    return res;
  } catch (error) {
    console.error('Error getting lesson:', error);
    throw error;
  }
};


// notes
export const getNotes = async (courseId) => {
  try {
    const res = await api.get(`api/courses/${courseId}/notes/`);
    return res;
  } catch (error) {
    console.error('Error getting notes:', error);
    throw error;
  }
}

export const createNote = async (lessonId, payload) => {
  try {
    const res = await api.post(`api/courses/note/${lessonId}/`, payload);
    return res;
  } catch (error) {
    console.error('Error creating note:', error);
    throw error;
  }
}


export const updateNote = async (noteId, payload) => {
  try {
    const res = await api.put(`api/courses/notes/${noteId}/`, payload);
    return res;
  } catch (error) {
    console.error('Error updating note:', error);
    throw error;
  }
}

export const deleteNote = async (noteId) => {
  try {
    const res = await api.delete(`api/courses/notes/${noteId}/`);
    return res;
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
}
