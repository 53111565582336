import { Link } from "react-router-dom";
import { Link as MuiLink, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { useRef, useEffect, useState } from "react";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#026670",
    padding: pxToRem(10),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#026670",
  },
});

const MenuDropdownLink = (props) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    };
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const linkContent = (
    <span ref={textRef} className="overflow-ellipsis">
      {props.children}
    </span>
  );

  return isOverflow ? (
    <CustomTooltip title={props.children} placement="right">
      <MuiLink
        {...props}
        to={props.to}
        underline="none"
        component={Link}
        sx={{
          fontSize: pxToRem(15),
          fontWeight: 600,
          color: "#333",
          "&:hover": { color: "secondary.main" },
        }}
      >
        {linkContent}
      </MuiLink>
    </CustomTooltip>
  ) : (
    <MuiLink
      {...props}
      to={props.to}
      underline="none"
      component={Link}
      sx={{
        fontSize: pxToRem(15),
        fontWeight: 600,
        color: "#333",
        "&:hover": { color: "secondary.main" },
      }}
    >
      {linkContent}
    </MuiLink>
  );
};

export default MenuDropdownLink;