import { Box, useMediaQuery,  useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import ModalProvider from "mui-modal-provider";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import Toast from "./Toast";

const Layout = () => {
  const theme = useTheme();
  const location = useLocation();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  // verify email
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  // hide footer
  const shouldHideFooter = mobile_ssm && location.pathname.startsWith('/course/') && !location.pathname.includes('presubscribe');

  useEffect(() => {
    console.log(`Current path: ${location.pathname}`); 
    window.scrollTo(0, 0);

    const message = localStorage.getItem("successMessage");
    if (message) {
      setSuccessMessage(message);
      setOpen(true);
      localStorage.removeItem("successMessage");
    }

  }, [location.pathname]);

  const handleClose = ()=>{
    setOpen(false)
  }

  return (
    <ModalProvider>
      <Box
        position="relative"
        maxWidth={pxToRem(1920)}
        m="0 auto"
        p={{ xs: 0, md: pxToRem(30) }}
        sx={{ overflowX: {ssm: "hidden"} }}
      >
        <Outlet />
        <Toast open={open} message={successMessage} onClose={handleClose} />
        {!shouldHideFooter && <Footer />}
      </Box>
    </ModalProvider>
  );
};

export default Layout;
