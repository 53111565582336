import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Hero from "./Hero";
import TagsCloud from "../../components/TagsCloud";
import Presentation from "./Presentation";
import Philosophy from "./Philosophy";
import BlogNews from "./BlogNews";
import FAQ from "./FAQ";
import Parsa from "./Parsa";
import Guides from "../../components/Guides";
import Courses from "./Courses";
import Promotion from "./Promotion";
import VideoSlider from "./VideoSlider";
import {getStudent} from "../../api/student";
import { useAuth } from "../../utils/AuthContext";

const Home = () => {

  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();


  console.log("isAuthenticated", isAuthenticated);

  const [student, setStudent] = useState(null);


  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await getStudent();
        setStudent(response.data);
      } catch (error) {
        console.error("Failed to fetch student data:", error);
      }
    };

    fetchStudent();
  }, []);

  
  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== 'string') return ""; // check if str is undefined, null, or not a string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const nickName = student?.nickname ? capitalizeFirstLetter(student.nickname) : "";
  const firstName = student?.user?.first_name ? capitalizeFirstLetter(student.user.first_name) : "";
  const lastName = student?.user?.last_name ? capitalizeFirstLetter(student.user.last_name) : "";
  
  const fullName = `${firstName} ${lastName}`;


  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Hero />
      <TagsCloud />
      {isAuthenticated ? ( <VideoSlider name={nickName || firstName} />) : (<Box height={120} /> )}
      <Presentation />
      <Philosophy />
      <Courses/>
      <BlogNews />
      <FAQ />
      <Parsa />
      <Promotion />
    </Box>
  );
};

export default Home;
