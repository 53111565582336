import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import ModalHeader from "../../../components/ModalHeader";
import { ReactComponent as Check } from "../../../images/check.svg";
import { ReactComponent as Close } from "../../../images/cross-white.svg";
import { Stack } from "@mui/system";

const SubscriptionFeedbackModal = ({
  open,
  handleClose,
  success,
  cancel,
  modalTitle,
  errorMessage,
}) => {
  const handleStartWatching = () => {
    handleClose();
  };

  const handleTryAgain = () => {
    console.log("clicked");
  };
  // alert(errorMessage)
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "500px !important",
            borderRadius: pxToRem(20),
            verticalAlign: "center",
            backgroundColor: "#F7F6F2",
            margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`}
          },
        }}
      >
        <ModalHeader
          title={modalTitle}
          inModal={true}
          onClose={() => handleClose()}
          // isFeedBack
        />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: `${pxToRem(30)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(40)}`, sm: `${pxToRem(40)} ${pxToRem(60)}` },
            backgroundColor: "#f7f6f2",
            overflow: "visible",
            position: "relative"
          }}
        >
          <Box textAlign="center">
            <Stack
              position="absolute"
              top={pxToRem(-27)}
              left="50%"
              alignItems="center"
              justifyContent="center"
              bgcolor={success ? "#026670" : "#C92727"}
              width={pxToRem(54)}
              height={pxToRem(54)}
              borderRadius="100%"
              sx={{ transform: "translatex(-50%)" }}
            >
              {success ? (
                <Check width={16} height={16} color="#FCE181" />
              ) : (
                <Close width={16} height={16} color="#FCE181" />
              )}
            </Stack>
            <Box sx={{ marginTop: pxToRem(10) }}>
              <Typography
                component="p"
                fontSize={pxToRem(20)}
                fontWeight={700}
              >
                {success ? (
                  modalTitle !== "Invitation Feedback" ? (
                    <>
                      Thank you.
                      <br />
                      Payment Successful!
                    </>
                  ) : (
                    <>
                      {/* Thank you.
                      <br /> */}
                      Invitaion was rejected successful!
                    </>
                  )
                ) : (
                  <>
                    Ooops!
                    <br />
                    That didn’t work!
                  </>
                )}
              </Typography>
              {success && modalTitle === "Gift Course"&&(
                <Typography
                variant="medium"
                component="p"
                fontSize={pxToRem(16)}
                mt={pxToRem(20)}
              >
               An email invitation has been sent to the recipient, and a receipt has been sent to your email.
              </Typography>
              
              )}
              {success && modalTitle === "Add Partner"&&(
                <Typography
                variant="medium"
                component="p"
                fontSize={pxToRem(16)}
                mt={pxToRem(20)}
              >
              An email confirmation was sent.
              </Typography>
              
              )}

              {errorMessage && (
                <Typography
                  variant="medium"
                  component="p"
                  fontSize="16px"
                  mt={pxToRem(20)}
                  lineHeight="1.5" 
                >
                  {errorMessage}
                </Typography>
              )}

              {success &&
                modalTitle !== "Add Partner" &&
                modalTitle !== "Gift Course" &&
                modalTitle !== "Invitation Feedback" && (
                  <Button
                    variant="yellow"
                    sx={{ width: {xs: "100%", ssm: "300px"}, maxWidth: {xs: "300px", ssm: "none"}, mt: pxToRem(40), height: pxToRem(54) }}
                    onClick={handleStartWatching}
                  >
                    Start Watching
                  </Button>
                )}
              {cancel && (
                <Button
                  // onClick={openPurchaseModal}
                  variant="yellow"
                  sx={{ width: {xs: "100%", ssm: "300px"}, maxWidth: {xs: "300px", ssm: "none"}, mt: pxToRem(40), height: pxToRem(54) }}
                  onClick={handleTryAgain}
                >
                  Try Again
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionFeedbackModal;
