import { Box, Button, Typography } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import Slider from "../Slider";

const content = [
  {
    id: 0,
    title: "Parsha",
    url: "/",
  },
  {
    id: 1,
    title: "Questions & Answers",
    url: "/",
  },
  {
    id: 2,
    title: "Our Products",
    url: "/",
  },
  {
    id: 3,
    title: "Something Here",
    url: "/",
  },
  {
    id: 4,
    title: "Something Here",
    url: "/",
  },
];

const ContentSlider = () => {
  return (
    <Box pb="100px">
      <Typography
        variant="sectionTitle"
        component="h2"
        fontSize="35px"
        mb="35px"
      >
        <span className="highlighted">Other</span> Content
      </Typography>

      <Box
        sx={{
          "& .MuiContainer-root": {
            p: "0 100px",
          },
          "& .swiper-slide": { width: "auto" },
          "& .arrow-prev": { left: "-100px" },
          "& .arrow-next": { right: "-100px" },
        }}
      >
        <Slider slidesPerView="auto" spaceBetween={10} arrows>
          {content.map((v) => (
            <SwiperSlide key={v.id}>
              <Button variant="outlined">{v.title}</Button>
            </SwiperSlide>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default ContentSlider;
