import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReCAPTCHA from "react-google-recaptcha";
import {
  requestResendPassword,
  requestResetPassword,
  resetPassword,
} from "../../api/student";
import getErrorsArray from "../../utils/getErrorsArray";
import FormInputPhone from "../../components/FormInputPhone";
import ModalHeader from "../../components/ModalHeader";
import { captchaKey } from "../../api/auth";
import Toast from "../../components/Toast";
import { pxToRem } from "px2rem2px";
import ResendCode from "../../components/ResendCode";

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)({
  position: "relative",
  width: pxToRem(300),
  justifyContent: "space-between",
  backgroundColor: "#BFBEBB",
  borderRadius: pxToRem(30),
  padding: pxToRem(2),
  "&::before": {
    content: "''",
    width: pxToRem(147),
    height: pxToRem(50),
    position: "absolute",
    left: pxToRem(2),
    backgroundColor: "#EDECE8",
    borderRadius: pxToRem(30),
    transition: "left 0.2s ease-in-out",
  },
  "&.active::before": {
    left: pxToRem(151),
  },
});
const ToggleButtonStyled = styled(ToggleButton)({
  color: "#EDECE8",
  width: pxToRem(147),
  height: pxToRem(50),
  borderRadius: `${pxToRem(30)} !important`,
  fontSize: pxToRem(16),
  fontWeight: 600,
  textTransform: "capitalize",
  border: "none",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#026670",
  },
  "&.Mui-selected": {
    color: "#026670",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const ButtonStyled = styled(Button)({
  width: "200px",
  height: "54px",
  fontSize: "14px",
  fontWeight: 600,
  margin: "10px 0",
});

const ButtonLogin = styled(Button)({
  width: pxToRem(300),
  height: `${pxToRem(54)} !important`,
  fontSize: pxToRem(14),
  fontWeight: 600,
  boxShadow: "none",
  margin: `${pxToRem(20)} 0`,
});

export default function PasswordResetRequest({
  setAction,
  setOpenSuccess,
  ...props
}) {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState("");
  const [errors, setErrors] = useState({});
  const [useEmail, setUseEmail] = useState(true);
  const [touched, setTouched] = useState({
    contactInfo: false,
    code: false,
    password: false,
    confirmPassword: false,
  });
  const captchaRef = useRef();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [verifyForm, setVerifyForm] = useState({
    code: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (touched.contactInfo && useEmail) {
      const emailValid = validateEmail(contactInfo);
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactInfo: emailValid ? "" : "Invalid email address",
      }));
    }
  }, [contactInfo, useEmail, touched.contactInfo]);

  useEffect(() => {
    if (touched.password || touched.confirmPassword) {
      const passwordValid = verifyForm.password.length >= 8;
      const passwordsMatch = verifyForm.password === verifyForm.confirmPassword;
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: passwordValid ? "" : "Password must be at least 8 characters",
        confirmPassword: passwordsMatch ? "" : "Passwords do not match",
      }));
    }
  }, [
    verifyForm.password,
    verifyForm.confirmPassword,
    touched.password,
    touched.confirmPassword,
  ]);

  useEffect(() => {
    if (touched.code) {
      const codeValid = verifyForm.code.length > 0;
      setErrors((prevErrors) => ({
        ...prevErrors,
        code: codeValid ? "" : "Verification code cannot be empty",
      }));
    }
  }, [verifyForm.code, touched.code]);

  const handleCaptchaChange = (e) => {
    setCaptchaValue(captchaRef.current.getValue());
  };

  const handleTabChange = () => {
    setUseEmail(!useEmail);
    setContactInfo("");
    setErrors({});
    setTouched({ ...touched, contactInfo: false });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleResetRequest = () => {
    setTouched({ ...touched, contactInfo: true });
    if (useEmail && !validateEmail(contactInfo)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactInfo: "Invalid email address",
      }));
      return;
    }

    if (contactInfo.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactInfo: "This field is required",
      }));
      return;
    }

    const payload = {
      [useEmail ? "email" : "phone"]: contactInfo,
      captcha_value: captchaValue,
    };

    setIsLoading(true);
    requestResetPassword(payload)
      .then((res) => {
        setIsLoading(false);
        setStep(2);
        setToastOpen(true);
      })
      .catch((e) => {
        const err = e.response.data;
        setErrors((prevErrors) => ({
          ...prevErrors,
          contactInfo: err.email ? err.email[0] : "",
        }));
        setIsLoading(false);
      })
      .finally(() => {
        captchaRef.current.reset();
      });
  };

  const handleVerify = () => {
    setTouched({
      ...touched,
      code: true,
      password: true,
      confirmPassword: true,
    });

    if (
      errors.password ||
      errors.confirmPassword ||
      errors.code ||
      verifyForm.code.length === 0
    ) {
      return;
    }

    const payload = {
      reset_code: verifyForm.code,
      new_password: verifyForm.password,
      confirm_password: verifyForm.confirmPassword,
      captcha_value: captchaValue,
    };

    setIsLoading(true);
    resetPassword(payload)
      .then((res) => {
        setIsLoading(false);
        setOpenSuccess(true);
        setAction("login");
      })
      .catch((e) => {
        const err = e.response.data;
        setErrors((prevErrors) => ({
          ...prevErrors,
          code: err.non_field_errors ? err.non_field_errors[0] : "",
        }));
        setIsLoading(false);
      })
      .finally(() => {
        captchaRef.current.reset();
      });
  };

  // resend code
  const [startTimer, setStartTimer] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const handleResendCode = () => {
    const payload = {
      [useEmail ? "email" : "phone"]: contactInfo,
      captcha_value: captchaValue,
    };

    setIsLoading(true);
    requestResendPassword(payload)
      .then((res) => {
        setIsLoading(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Verification code resent successfully.",
        }));
        setToastOpen(true);
        setStartTimer(true);
      })
      .catch((e) => {
        const err = e.response.data;
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: err.email
            ? err.email[0]
            : "Failed to resend verification code.",
        }));
        setIsLoading(false);
      })
      .finally(() => {
        captchaRef.current.reset();
      });
  };

  return (
    <>
      <Toast
        open={toastOpen}
        message="Verification code was sent to your email."
        onClose={() => {
          setToastOpen(false);
        }}
      />
      <ModalHeader
        title={step === 1 ? "Request Password Reset" : "Reset Your Password"}
        inModal={props?.inModal}
        onClose={() => props.onClose()}
      />
      <Box
        sx={{
          minWidth: "400px",
          borderRadius: "10px",
          backgroundColor: "#f7f6f2",
          display: "flex",
          justifyContent: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "#f7f6f2",
            padding: "40px",
            borderRadius: "10px",

            maxWidth: "400px",
            margin: "auto",
          }}
        >
          {step === 1 ? (
            <>
              <Box
                position="relative"
                textAlign="center"
                mt={pxToRem(-75)}
                mb={pxToRem(20)}
              >
                <ToggleButtonGroupStyled
                  className={`${!useEmail ? "active" : ""}`}
                  value={useEmail ? "email" : "phone"}
                  exclusive
                  onChange={handleTabChange}
                >
                  <ToggleButtonStyled value="email">Email</ToggleButtonStyled>
                  <ToggleButtonStyled value="phone">Phone</ToggleButtonStyled>
                </ToggleButtonGroupStyled>
              </Box>
              {useEmail ? (
                <>
                  <Typography
                sx={{
                  mb: "10px",
                  marginTop: "10px",
                  fontWeight: 500,
                  fontSize: "18px",
                  textAlign: "center",
                  "@media(max-width: 380px)": {
                    fontSize: "16px",
                  },
                }}
              >
                    Enter your Email address to reset your password
                  </Typography>
                  <TextFieldStyled
                    placeholder="Email"
                    value={contactInfo}
                    onChange={(e) => setContactInfo(e.target.value)}
                    onBlur={() => setTouched({ ...touched, contactInfo: true })}
                    error={touched.contactInfo && Boolean(errors.contactInfo)}
                    helperText={touched.contactInfo && errors.contactInfo}
                  />
                </>
              ) : (
                <>
                  <Typography
                sx={{
                  mb: "10px",
                  marginTop: "10px",
                  fontWeight: 500,
                  fontSize: "18px",
                  textAlign: "center",
                  "@media(max-width: 380px)": {
                    fontSize: "16px",
                  },
                }}
              >
                    Enter your Phone number to reset your password
                  </Typography>
                  <FormInputPhone
                    name="phone"
                    placeholder="Phone"
                    value={contactInfo}
                    onChange={(data) => setContactInfo(data.phone)}
                  />
                </>
              )}

              <ReCAPTCHA
                ref={captchaRef}
                sitekey={captchaKey}
                onChange={handleCaptchaChange}
              />
              <ButtonLogin
                disabled={isLoading}
                onClick={handleResetRequest}
                variant="yellow"
                color="primary"
              >
                Recover Your Password
              </ButtonLogin>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  mb: "10px",
                  marginTop: "10px",
                  fontWeight: 500,
                  fontSize: "18px",
                  textAlign: "center",
                  "@media(max-width: 380px)": {
                    fontSize: "16px",
                  },
                }}
              >
                Enter your verification code and type your new password
              </Typography>
              {/* <Typography
                sx={{ fontSize: "14px", color: "green", textAlign: "center" }}
              >
                A verification code has been sent to {contactInfo}
              </Typography> */}

              <ResendCode
                startTimer={startTimer}
                setStartTimer={setStartTimer}
                handleResend={handleResendCode}
                isLoading={isLoading}
              />

              <TextFieldStyled
                placeholder="Verification Code"
                autoComplete="off"
                value={verifyForm.code}
                onChange={(e) =>
                  setVerifyForm({
                    ...verifyForm,
                    code: e.target.value.slice(0, 6),
                  })
                }
                onBlur={() => setTouched({ ...touched, code: true })}
                inputProps={{ maxLength: 6 }}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code && errors.code}
              />
              <TextFieldStyled
                placeholder="New Password"
                autoComplete="new-password"
                value={verifyForm.password}
                onChange={(e) =>
                  setVerifyForm({ ...verifyForm, password: e.target.value })
                }
                onBlur={() => setTouched({ ...touched, password: true })}
                type="password"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextFieldStyled
                placeholder="Confirm New Password"
                autoComplete="new-password"
                value={verifyForm.confirmPassword}
                onChange={(e) => {
                  const confirmPasswordError =
                    e.target.value !== verifyForm.password;
                  setVerifyForm({
                    ...verifyForm,
                    confirmPassword: e.target.value,
                  });
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    confirmPassword: confirmPasswordError
                      ? "Passwords do not match"
                      : "",
                  }));
                }}
                onBlur={() => setTouched({ ...touched, confirmPassword: true })}
                type="password"
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={captchaKey}
                onChange={handleCaptchaChange}
              />
              <ButtonLogin
                disabled={isLoading}
                onClick={handleVerify}
                variant="yellow"
                color="primary"
              >
                Update Password
              </ButtonLogin>
            </>
          )}
          <Box sx={{ width: "100%" }}>
            <Divider sx={{ borderColor: "#BFBEBB", my: pxToRem(20) }} />
            <Typography
              onClick={() => setAction("login")}
              underline="hover"
              sx={{
                fontWeight: 600,
                color: "#BFBEBB",
                cursor: "pointer",
                caretColor: "transparent",
                textAlign: "center",
                margin: "20px 0px",
                "&:hover": {
                  textDecoration: "underline",
                },
                "@media(max-width: 380px)": {
                  fontSize: "12px",
                },
              }}
            >
              Go back to Login
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
