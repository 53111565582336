import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { requestResendVerifyEmail, verifyemail } from "../../api/student";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import ReCAPTCHA from "react-google-recaptcha";
import { saveStudentLocal } from "../../utils/AuthContext";
import { captchaKey } from "../../api/auth";
import ModalHeader from "../../components/ModalHeader";
import { useTheme } from "@emotion/react";
import ResendCode from "../../components/ResendCode";
import Toast from "../../components/Toast";

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  // "@media(max-width: 380px)": {
  //   "& .MuiInputBase-root": {
  //     height: pxToRem(44),
  //   },
  //   "& input": {
  //     padding: `${pxToRem(10)} ${pxToRem(30)}`,
  //   },
  // },
});

const ButtonLogin = styled(Button)({
  width: pxToRem(300),
  height: `${pxToRem(54)} !important`,
  fontSize: pxToRem(14),
  fontWeight: 600,
  boxShadow: "none",
  margin: `${pxToRem(40)} 0`,
  "@media(max-width: 380px)": {
    width: "100%",
    height: `${pxToRem(44)} !important`,
    fontSize: pxToRem(12),
  },
});

export default function VerifyEmail({
  setAction,
  setSuccessMessage,
  verifyEmail,
  openCodeSuccess,
  setOpenCodeSuccess,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const captchaRef = useRef();
  const [errors, setErrors] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [verifyForm, setVerifyForm] = useState({
    email: "",
    code: "",
  });

  console.log("verifyemailprop", props?.onRedirectOpenModal);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (verifyEmail) {
      setVerifyForm((prevForm) => ({ ...prevForm, email: verifyEmail }));
    }
  }, [verifyEmail]);

  // function handleVerify() {
  //   let hasError = false;

  //   if (!verifyForm.email) {
  //     setEmailError("Email cannot be empty");
  //     hasError = true;
  //   } else {
  //     setEmailError("");
  //   }

  //   if (!verifyForm.code) {
  //     setCodeError("Verification code cannot be empty");
  //     hasError = true;
  //   } else {
  //     setCodeError("");
  //   }

  //   if (hasError) {
  //     return;
  //   }

  //   const payload = {
  //     email: verifyForm.email,
  //     code: verifyForm.code,
  //     captcha_value: captchaValue,
  //   };

  //   if (props?.onRedirectOpenModal === "previewModal") {
  //     localStorage.setItem("onRedirectOpenModal", "previewModal");
  //   }
  //   if (props?.onRedirectOpenModal === "giftModal") {
  //     localStorage.setItem("onRedirectOpenModal", "giftModal");
  //   }

  //   console.log(payload);
  //   setIsLoading(true);
  //   verifyemail(payload)
  //     .then((res) => {
  //       console.log(res);
  //       localStorage.setItem("accessToken", res.data.access_token);
  //       localStorage.setItem("refreshToken", res.data.refresh_token);
  //       localStorage.setItem("successMessage", "Email verified successfully.");
  //       saveStudentLocal().then(() => {
  //         window.location.reload();
  //       });
  //       setIsLoading(false);
  //       setSuccessMessage("Email verified successfully");
  //     })
  //     .catch((e) => {
  //       const err = e.response.data;
  //       console.log("err.status", err);
  //       captchaRef.current.reset();
  //       setErrors(err.non_field_errors || []);
  //       setIsLoading(false);
  //     })
  //     .finally(() => {
  //       captchaRef.current.reset();
  //     });
  // }
  async function handleVerify() {
    try {
      let hasError = false;

      // Validate email
      if (!verifyForm.email) {
        setEmailError("Email cannot be empty");
        hasError = true;
      } else {
        setEmailError("");
      }

      // Validate code
      if (!verifyForm.code) {
        setCodeError("Verification code cannot be empty");
        hasError = true;
      } else {
        setCodeError("");
      }

      if (hasError) return;

      const payload = {
        email: verifyForm.email,
        code: verifyForm.code,
        captcha_value: captchaValue,
      };

      if (props?.onRedirectOpenModal === "previewModal") {
        localStorage.setItem("onRedirectOpenModal", "previewModal");
      }
      if (props?.onRedirectOpenModal === "giftModal") {
        localStorage.setItem("onRedirectOpenModal", "giftModal");
      }

      setIsLoading(true);

      const res = await verifyemail(payload);
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refreshToken", res.data.refresh_token);
      localStorage.setItem("successMessage", "Email verified successfully.");

      // Save student data and reload page
      await saveStudentLocal();
      window.location.reload();

      setSuccessMessage("Email verified successfully");
    } catch (e) {
      const err = e.response?.data || {};
      console.log("Error status:", err);

      setErrors(err.non_field_errors || []);
    } finally {
      captchaRef.current.reset();
      setIsLoading(false);
    }
  }

  // resend code
  const [startTimer, setStartTimer] = useState(false);

  const handleResendEmailCode = async () => {
    setIsLoading(true);
    try {
      setStartTimer(true);
      const response = await requestResendVerifyEmail(verifyForm.email);
      console.log("Email verification request sent:", response);
      setOpenCodeSuccess(true);
    } catch (error) {
      console.error("Error sending email verification request:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCaptchaChange = () => {
    setCaptchaValue(captchaRef.current.getValue());
  };

  const handleOnClose = () => {
    setErrors([]);
    props.onClose();
  };

  return (
    <Box>
      <Toast
        open={openCodeSuccess}
        message="Verification code was sent to your email."
        onClose={() => {
          setOpenCodeSuccess(false);
        }}
      />
      <ModalHeader
        title={"Verify Your Email"}
        inModal={props?.inModal}
        onClose={handleOnClose}
      />
      <Box
        p={` ${pxToRem(30)} ${pxToRem(40)}`}
        sx={{
          // maxWidth: "400px",
          // borderRadius: "10px",
          backgroundColor: "#f7f6f2",
          // display: "flex",
          // justifyContent: "center",

          //
          // flexDirection: "column",
          // alignItems: "center",
          // gap: "10px ",
          // mb: "8px",
          // "@media(max-width: 400px)": {
          //   paddingX: "20px",
          // },
          // "@media(max-width: 380px)": {
          //   padding: "20px",
          //   minWidth: "300px",
          // },
        }}
      >
        <Box
          sx={{
            maxwidth: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px ",
            mb: "8px",
            // backgroundColor: "yellow",
          }}
        >
          <Typography
            sx={{
              mb: "10px",
              marginTop: "10px",
              fontWeight: 500,
              fontSize: "18px",
              textAlign: "center",
              // "@media(max-width: 380px)": {
              //   fontSize: "16px",
              // },
            }}
          >
            Enter your email and your verification code
          </Typography>
          {/* {verifyEmail && (
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: "lighter",
                }}
                color={"green"}
              >
                An email was sent to {verifyEmail}
              </Typography>
            </Box>
          )} */}
          <ResendCode
            startTimer={startTimer}
            setStartTimer={setStartTimer}
            handleResend={handleResendEmailCode}
            isLoading={isLoading}
          />
          <Box sx={{ mb: "10px" }}>
            {errors?.map((error, index) => (
              <Typography
                key={index}
                sx={{ my: 0, fontSize: "1rem" }}
                color={"red"}
              >
                {error}
              </Typography>
            ))}
          </Box>
          {successMsg && (
            <Typography sx={{ my: 0, fontSize: "1rem" }} color={"green"}>
              {successMsg}
            </Typography>
          )}
          <Box sx={{ width: "100%", maxWidth: "360px" }}>
            <TextFieldStyled
              placeholder={"Email"}
              value={verifyForm.email}
              onChange={(e) => {
                setVerifyForm({ ...verifyForm, email: e.target.value });
                setEmailError("");
              }}
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
          <Box sx={{ width: "100%", maxWidth: "360px" }}>
            <TextFieldStyled
              placeholder="Verification Code"
              value={verifyForm.code}
              onChange={(e) => {
                const { value } = e.target;
                if (value.length <= 6) {
                  setVerifyForm({ ...verifyForm, code: value });
                  setCodeError("");
                }
              }}
              inputProps={{ maxLength: 6 }}
              error={!!codeError}
              helperText={codeError}
            />
          </Box>
          <Box
            sx={{
              mt: "30px",
              width: "100%",
              maxWidth: "360px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={captchaKey}
              onChange={handleCaptchaChange}
            />
          </Box>
          <Box sx={{ width: "100%", maxWidth: "360px" }}>
            <Button
              disabled={isLoading}
              onClick={handleVerify}
              variant="yellow"
              type="submit"
              sx={{
                // width: "350px",
                width: "100%",
                height: `${pxToRem(54)} !important`,
                fontSize: pxToRem(14),
                fontWeight: 600,
                boxShadow: "none",
                marginTop: `${pxToRem(40)} `,
                "@media(max-width: 380px)": {
                  // width: "100%",
                  height: `${pxToRem(44)} !important`,
                  fontSize: pxToRem(12),
                },
              }}
            >
              Verify Email
            </Button>
          </Box>

          <Divider
            sx={{
              borderColor: "#BFBEBB",
              width: "100%",
              maxWidth: "360px",
              marginTop: "30px",
            }}
          />
          <Typography
            onClick={() => setAction("login")}
            underline="hover"
            sx={{
              fontWeight: 600,
              color: "#BFBEBB",
              cursor: "pointer",
              caretColor: "transparent",
              textAlign: "center",
              margin: "20px 0px",
              "&:hover": {
                textDecoration: "underline",
              },
              "@media(max-width: 380px)": {
                fontSize: "12px",
              },
            }}
          >
            Go back to Login
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
