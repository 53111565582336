import { useEffect, useRef, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import restrictToWindowEdgesMargin from "../../../utils/restrictToWindowEdges";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import VideoPlayerDraggable from "../../../components/videoPlayer/VideoPlayerDraggable";

const defaultCoordinates = {
  x: -25,
  y: -25,
};

const VideoPlayer = (props) => {
  const [{ x, y }, setCoordinates] = useState(defaultCoordinates);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 1,
    },
  });

  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
    pointerSensor
  );

 

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) => ({
          x: x + delta.x,
          y: y + delta.y,
        }));
      }}
      modifiers={[restrictToWindowEdgesMargin]}
    >
      <VideoPlayerDraggable
        x={x}
        y={y}
        resetPosition={() => setCoordinates({ x: -25, y: -25 })}
        selectedLesson={props?.selectedLesson}
        preview={props?.preview}
        openResourcesTab={props?.openResourcesTab}
        startTime={props?.selectedLesson?.watch_time || 0}
        setPlayIcon={props?.setPlayIcon}
        playIcon={props?.playIcon}
        setVideoEnded={props?.setVideoEnded}
        videoEnded={props?.videoEnded}
        noListen={props?.noListen}
        setNextLecture={props?.setNextLecture}
        nextLecture={props?.nextLecture}
        lastLesson={props?.lastLesson}
        isCourseLoading={props?.isLoading}
        id={props?.id}
        bookmarkStartTime={props?.bookmarkStartTime}
        handleNextLectureClick={props?.handleNextLectureClick}
        lessons={props?.lessons}
        mediaControlsHeight={props.mediaControlsHeight}
        modalScreen={props.modalScreen}
        setBookmarkListChanged={props?.setBookmarkListChanged}
        setBlockAutoLectureId={props?.setBlockAutoLectureId}
        setBookmarkStartTime={props?.setBookmarkStartTime}
        setNoteListChanged={props?.setNoteListChanged}

        currentTime={props?.currentTime}
        setCurrentTime={props?.setCurrentTime}
      />
    </DndContext>
  );
};

export default VideoPlayer;
