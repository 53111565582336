import {
  Button,
  Stack,
  Typography,
  Link,
  Divider,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import TextLink from "../../components/TextLink";
import Rating from "../../components/Rating";
import Testimonial from "../../components/Testimonial";
import RichTextDisplay from "../../components/displayRichText";

import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { useEffect, useRef, useState } from "react";
import { Box, display, width } from "@mui/system";
import { formatDate, formatDuration } from "../../utils/format";

const LinkStyled = styled(Link)({
  color: "#026670",
  fontWeight: "600",
  cursor: "pointer",
  "&:hover": { color: "#333", borderBottom: "2px solid #333" },
});

const TypographyStyled = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: pxToRem(2),
  fontSize: pxToRem(14),
  fontWeight: 600,
  lineHeight: pxToRem(18),
  color: "#BFBEBB",
  "& span": {
    color: "#333",
  },
});

const OverviewTab = (props) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  const [isAlignedRight, setIsAlignedRight] = useState(false);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(props?.description, "text/html");
    const hasAlignRightClass = doc.querySelector(".ql-align-right") !== null;
    setIsAlignedRight(hasAlignRightClass);
  }, [props?.description]);

  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setShowFullDescription((prev) => !prev);
  };

  const handleContentExceedsMaxHeight = (exceedsMaxHeight) => {
    setShowReadMore(exceedsMaxHeight);
  };

  console.log("props", props.subscriber_count);
  return (
    <>
      <Typography
        variant="sectionTitle"
        component="h2"
        fontSize={{xs: pxToRem(20), ssm: pxToRem(35)}}
        mb={pxToRem(20)}
        order="3"
      >
        Course Description
      </Typography>

      <BlockBordered
        str={props?.description}
        mr={true}
        style={{ position: "relative" }}
        sx={{width: "2px !important", marginRight: {xs: "0", md: "20px"}, display: {xs: "none", ssm: "block"}}}
        
      >
        {props?.description ? (
          <>
            <Box sx={{"& p": {margin: 0}}}>
              <RichTextDisplay
                str={props.description}
                showFull={showFullDescription}
                onContentExceedsMaxHeight={handleContentExceedsMaxHeight}
              />
            </Box>

            {showReadMore && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isAlignedRight ? "flex-end" : "flex-start",
                  mt: 1,
                }}
              >
                <TextLink
                  href=""
                  onClick={handleReadMoreClick}
                  showFullDescription={showFullDescription}
                >
                  {showFullDescription ? "Show Less" : "Read More"}
                </TextLink>
              </Box>
            )}
          </>
        ) : (
          <Box>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="90%" />
          </Box>
        )}
      </BlockBordered>

      <Stack
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
        columnGap={{xs: pxToRem(30), md: pxToRem(20)}}
        rowGap={pxToRem(10)}
        mt={{xs: pxToRem(20), ssm: pxToRem(40)}}
        order="1"
        display={{xs: "grid", ssm: "flex"}}
        gridTemplateColumns= {{xs: "auto auto", sssm: "1fr 1fr", ssm: "unset" }}
        justifyContent= {{ xs: "space-between", ssm: "unset" }}
      >
        <TypographyStyled>
          Length:{" "}
          <span style={{ marginLeft: '4px' }}>
            {props?.length ? (
              formatDuration(props.length)
            ) : (
              <Skeleton width={50} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled>
          Release:{" "}
          <span style={{ marginLeft: '4px' }}>
            {props?.release_date ? (
              formatDate(props?.release_date)
            ) : (
              <Skeleton width={100} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled>
          Subscribers:{" "}
          <span style={{ marginLeft: '4px' }}>
            {props?.subscriber_count !== undefined ? (
              props.subscriber_count
            ) : (
              <Skeleton width={30} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled>
          Lectures:{" "}
          <span style={{ marginLeft: '4px' }}>
            {props?.lessons_count !== undefined ? (
              props.lessons_count
            ) : (
              <Skeleton width={30} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled sx={{ gridColumn: 'span 2' }}>
          Rating:{" "}
          {props?.rating !== undefined ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft:"4px"
                }}
              >
                <Rating
                  defaultValue={props.rating}
                  isCourseCard={true}
                  readOnly
                />
              <span style={{paddingLeft: pxToRem(10)}}>{props.rating}</span>
              </Box>
            </>
          ) : (
            <Skeleton width={80} />
          )}
        </TypographyStyled>
      </Stack>

      <Divider sx={{ backgroundColor: "#BFBEBB", my: {xs: pxToRem(38), ssm: pxToRem(60)}, order:"2" }} />
      <Typography display={{xs: "none", ssm: "block"}} fontSize={pxToRem(22)} fontWeight={700} mb={pxToRem(20)}>
        Course Questions
      </Typography>
      <Swiper className="hide-in-mobile" slidesPerView={1.5} spaceBetween={20} 
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
        }}>
        <SwiperSlide>
          <Testimonial modal avatar reply />
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial modal reply />
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial modal avatar reply />
        </SwiperSlide>
      </Swiper>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        display={{xs: "none", ssm: "flex"}}
        mt={pxToRem(50)}
      >
        <Button variant="yellow" sx={{ height: pxToRem(54) }}>
          Rate & Comment
        </Button>
        <TextLink href="">View all 28 Comments</TextLink>
      </Stack>
    </>
  );
};

export default OverviewTab;
