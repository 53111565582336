import { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import FormInputText from "../../../components/FormInputText";
import { useForm } from "react-hook-form";
import { editProfile } from "../../../api/student";
import ConfirmCodeModal from "./ConfirmCodeModal";

const EmailInputEdit = ({
  label,
  editLabel,
  value,
  type,
  rules,
  fetchStudent,
  originalEmail,
}) => {
  const inputStyles = {
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      height: pxToRem(54),
      outline: "2px solid #333",
      outlineOffset: "-2px",
      backgroundColor: "#fff",
      width: "268px",
      "&.Mui-disabled": {
        backgroundColor: "#EDECE8",
        outline: "none",
      },
      "&.Mui-error": {
        outlineColor: "#d32f2f",
      },
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      color: "#333",
      fontWeight: 600,
      "&.Mui-disabled": {
        color: "#BFBEBB",
      },
    },
  };

  const [savedValue, setSavedValue] = useState(value || "");
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [resendTimer, setResendTimer] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    setSavedValue(value);
  }, [value]);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors: formErrors },
    watch,
  } = useForm({
    defaultValues: {
      value: value || "",
    },
  });

  useEffect(() => {
    setValue("value", value);
  }, [value, setValue]);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const getErrorsArray = (errorData) => {
    if (typeof errorData === "string") {
      return [errorData];
    } else if (Array.isArray(errorData)) {
      return errorData;
    } else {
      return Object.values(errorData).flat();
    }
  };

  const sendVerificationCode = () => {
    setLoading(true);
    const email = watch("value").toLowerCase();
    const oldEmail = value.toLowerCase();

    if (isValidEmail(email)) {
      if (email === oldEmail) {
        setError("value", {
          type: "manual",
          message:
            "New email address cannot be the same as the old email address.",
        });
        setLoading(false);
        return;
      }

      editProfile({ user: { email } })
        .then((res) => {
          if (res.status !== 202 && res.status !== 200) {
            throw new Error(res.data);
          }

          clearErrors("value");
          setLoading(false);
          setResendTimer(60);
          setOpenModal(true);
          setNewEmail(email);
        })
        .catch((error) => {
          setLoading(false);
          let message = "Unexpected error occurred.";
        
          if (error.response) {
            const contentType = error.response.headers['content-type'];
            if (contentType && contentType.includes('application/json')) {
              message = getErrorsArray(error.response.data).join(", ");
            }
          } else {
            message = "Network error. Please try again later.";
          }
          setError("value", {
            type: "manual",
            message: message,
          });
        });
        // .catch((error) => {
        //   setLoading(false);
        //   setError("value", {
        //     type: "manual",
        //     message: getErrorsArray(error.response.data).join(", "),
        //   });
        // });
    } else {
      setLoading(false);
      setError("value", {
        type: "manual",
        message: "Please enter a valid email address.",
      });
    }
  };

  const onSubmit = () => {
    sendVerificationCode();
  };

  const resetForm = () => {
    setValue("value", savedValue, { shouldValidate: true });
    setDisabled(true);
  };

  const handleEndEditMode = (isVerified) => {
    setDisabled(true);

    fetchStudent(); // Fetch student info after email update

    setValue("value", originalEmail); // Reset to original email
  };

  return (
    <>
      <Stack
        flexDirection={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "flex-start", lg: "flex-start" }}
        gap={{ xs: "10px", lg: 0 }}
      >
        <Typography
          width={{ xs: "auto", lg: "130px" }}
          mt={{ xs: 0, lg: "13px" }}
          variant="medium"
        >
          {label}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack flex={1} flexDirection="row" gap={pxToRem(20)}>
            <Box maxWidth="268px">
              <FormInputText
                name="value"
                control={control}
                setValue={setValue}
                rules={rules}
                muiProps={{
                  type: type,
                  disabled: disabled,
                  sx: inputStyles,
                }}
              />
            </Box>

            <Stack alignItems="flex-start" width="42px">
              {disabled ? (
                <Button
                  onClick={() => setDisabled(false)}
                  sx={{ fontSize: "12px", mt: "16px" }}
                >
                  Edit
                </Button>
              ) : (
                <Stack alignItems="flex-start" mt="10px">
                  <Button
                    onClick={resetForm}
                    sx={{ color: "#BFBEBB", fontSize: "12px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" sx={{ fontSize: "12px" }}>
                    Change
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </form>
      </Stack>

      <ConfirmCodeModal
        open={openModal}
        setOpen={setOpenModal}
        oldEmail={value}
        email={newEmail}
        handleEndEditMode={handleEndEditMode}
      />
    </>
  );
};

export default EmailInputEdit;
