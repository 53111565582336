import { useState } from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import ModalHeader from "../../components/ModalHeader";
import { pxToRem } from "px2rem2px";

const LoginSubscribeModal = (props) => {
  const { openLoginModal, hideModal, loginMessage ,onRedirectOpenModal } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    hideModal();
  };

  console.log("onRedirectOpenModal",onRedirectOpenModal)

  return (
    <>
      <Dialog
        open={isOpen}
        scroll="body"
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "550px !important",
            borderRadius: pxToRem(20),
            backgroundColor: "#F7F6F2",
            margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`}
          },
          zIndex: 1500,
        }}
      >
        <ModalHeader
          title="Authentication Required"
          inModal={true}
          onClose={handleClose}
          isFeedBack
        />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: {xs: `${pxToRem(30)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(40)}`, sm: `${pxToRem(40)} ${pxToRem(60)}`},
            backgroundColor: "#f7f6f2",
          }}
        >
          <Typography
            variant="medium"
            component="p"
            fontWeight={600}
            fontSize={pxToRem(16)}
            textAlign="center"
          >
            {loginMessage}
          </Typography>
          <Button
            variant="yellow"
            onClick={() => {
              handleClose();
              openLoginModal();
            }}
            sx={{ width: {xs: "100%", ssm: "300px"}, maxWidth: {xs: "300px", ssm: "none"}, mt: pxToRem(40), height: pxToRem(54) }}
          >
            Sign In or Create Account
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginSubscribeModal;
