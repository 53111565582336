import { Box, Typography } from "@mui/material";

const Category = (props) => {
  return (
    <Box>
      <Box
        position="relative"
        mb="45px"
  
        width="100%"
        sx={{
          "&::before": {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%",
            height: "2px",
            backgroundColor: "#FCE181",
            zIndex: -1,
          },
        }}
      >
        <Typography
          component="span"
          fontSize="14px"
          fontWeight={600}
          color="#026670"
          bgcolor="#FCE181"
          borderRadius="calc(infinity * 1px)"
          p="2px 30px"
        >
          {props.title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "40px 20px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default Category;
