import { Box, Tab, Tabs } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as VideoIcon } from "../../images/video.svg"
import { ReactComponent as Bookmark } from "../../images/bookmark.svg"
import { ReactComponent as Play } from "../../images/play.svg"
import { ReactComponent as Link } from "../../images/link.svg"
import { ReactComponent as Resources } from "../../images/resources.svg"

function DangerousLabel({ html }) {
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}

const FooterTabs = ({activeTab, setActiveTab, subscriptionStatus, hasContent}) => {
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return <Box sx={{display: {ssm: "none"}, boxShadow: "0px -4px 8px #00000012", borderRadius: "10px 10px 0px 0px", padding: `${pxToRem(14)} ${pxToRem(15)} ${pxToRem(7)} ${pxToRem(15)}`, position: "fixed", bottom: 0, width: "100%", zIndex: 2, backgroundColor: "#FFFFFF" }}>
    <Tabs value={activeTab} onChange={handleChange} aria-label="icon label tabs example" TabIndicatorProps={{ style: { display: 'none' }}} sx={{"& .MuiTabs-flexContainer": {alignItems: "start"}}}>
      <Tab sx={{padding: 0, '&.Mui-selected': { "& svg": {color: "#026670"}, color: "#026670"}, "& svg": { color: "#bcbcbc", transition: "all 200ms ease-in"}, "&:hover": {"& svg": { color: "#026670" }, color: "#026670"}, cursor: "pointer", color: "#BCBCBC", fontSize: {xs: pxToRem(10), sssm: pxToRem(12)}, fontWeight: 600, lineHeight: "10px", transition: "all 200ms ease-in", textAlign: "center", flexGrow: 1, minWidth: "auto", minHeight: "auto", textTransform: "capitalize"}} icon={<Play style={{height: pxToRem(22)}} />} label="Lectures" />
      <Tab sx={{padding: 0, '&.Mui-selected': { "& svg": {color: "#026670"}, color: "#026670"}, "& svg": { color: "#bcbcbc", transition: "all 200ms ease-in"}, "&:hover": {"& svg": { color: "#026670" }, color: "#026670"}, cursor: "pointer", color: "#BCBCBC", fontSize: {xs: pxToRem(10), sssm: pxToRem(12)}, fontWeight: 600, lineHeight: "10px", transition: "all 200ms ease-in", textAlign: "center", flexGrow: 1, minWidth: "auto", minHeight: "auto", textTransform: "capitalize"}} icon={<VideoIcon style={{height: pxToRem(23)}} />} label="Subscription"  />
      <Tab sx={{padding: 0, '&.Mui-selected': { "& svg": {color: "#026670"}, color: "#026670"}, "& svg": { color: "#bcbcbc", transition: "all 200ms ease-in"}, "&:hover": {"& svg": { color: "#026670" }, color: "#026670"}, cursor: "pointer", color: "#BCBCBC", fontSize: {xs: pxToRem(10), sssm: pxToRem(12)}, fontWeight: 600, lineHeight: "10px", transition: "all 200ms ease-in", textAlign: "center", flexGrow: 1, minWidth: "auto", minHeight: "auto", textTransform: "capitalize"}} icon={<Bookmark style={{height: pxToRem(22)}} />} label={<DangerousLabel html="Notes &<br/> Bookmarks" />} />
      {subscriptionStatus !== "Paused" && subscriptionStatus !== "Expired" && <Tab sx={{padding: 0, '&.Mui-selected': { "& svg": {color: "#026670"}, color: "#026670"}, "& svg": { color: "#bcbcbc", transition: "all 200ms ease-in"}, "&:hover": {"& svg": { color: "#026670" }, color: "#026670"}, cursor: "pointer", color: "#BCBCBC", fontSize: {xs: pxToRem(10), sssm: pxToRem(12)}, fontWeight: 600, lineHeight: "10px", transition: "all 200ms ease-in", textAlign: "center", flexGrow: 1, minWidth: "auto", minHeight: "auto", textTransform: "capitalize"}} icon={<Link style={{height: pxToRem(23)}} />} label="Connect" />}
      {hasContent && subscriptionStatus !== "Paused" && subscriptionStatus !== "Expired" &&<Tab sx={{padding: 0, '&.Mui-selected': { "& svg": {color: "#026670"}, color: "#026670"}, "& svg": { color: "#bcbcbc", transition: "all 200ms ease-in"}, "&:hover": {"& svg": { color: "#026670" }, color: "#026670"}, cursor: "pointer", color: "#BCBCBC", fontSize: {xs: pxToRem(10), sssm: pxToRem(12)}, fontWeight: 600, lineHeight: "10px", transition: "all 200ms ease-in", textAlign: "center", flexGrow: 1, minWidth: "auto", minHeight: "auto", textTransform: "capitalize"}} icon={<Resources style={{height: pxToRem(22)}} />} label="Resources" />}
    </Tabs>
  </Box>
}

export default FooterTabs;