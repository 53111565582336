import React, { useState, useEffect, useRef } from "react";
import {
  createBookmark,
  createNote,
  deleteBookmark,
  deleteNote,
  getNotes,
  listBookmarks,
  updateNote,
} from "../../api/course";
import {
  Badge,
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import TextLink from "../../components/TextLink";
import BlockBordered from "../../components/BlockBordered";
import Note from "./Note";
import IconBtn from "./IconBtn";
import DeleteBtn from "./DeleteBtn";
import { ReactComponent as Download } from "../../images/writing.svg";
import { ReactComponent as Print } from "../../images/printer.svg";
import Chevron from "../../images/chevron.svg";
import { useTheme } from "@emotion/react";
import Toast from "../../components/Toast";
import AddNoteDialog from "../../components/AddNoteDialog";

const Block = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: pxToRem(25),
  [theme.breakpoints.up("ssm")]: {
    padding: `${pxToRem(30)} ${pxToRem(40)} ${pxToRem(40)} ${pxToRem(40)}`,
    borderRadius: pxToRem(20),
  },
}));

const NotesBookmarks = ({
  course,
  bookmarks,
  setBookmarks,
  // notes,
  jumpToLatestProgress,
  jumpToBookMark,
  isCourseCompleted,
  bookmarkListChanged,
  noteListChanged,
  setNoteListChanged,
  setBookmarkListChanged,
  selectedLesson,
  currentTime,
}) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const bookmarksRef = useRef(null);
  const [bookmarksAccordianIsOpen, setBookmarksAccordianIsOpen] =
    useState(false);

  // notes
  const [notes, setNotes] = useState([]);
  const [noteTimestamp, setNoteTimestamp] = useState(0);
  const [noteToastOpen, setNoteToastOpen] = useState(false);
  const [NoteToastMessage, setNoteToastMessage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingNotes, setEditingNotes] = useState({});
  const [noteContents, setNoteContents] = useState({});

  const getAllNotes = async () => {
    try {
      const response = await getNotes(course?.id);
      setNotes(response.data.results);
    } catch (error) {
      console.error("Error getting notes:", error);
    }
  };

  useEffect(() => {
    getAllNotes();
  }, [course?.id, noteListChanged]);



  const handleUpdateNote = async (noteId, content) => {
    console.log("noteId", noteId);
    console.log("content", content);
    setNoteListChanged(true);
    const payload = {
      lesson: selectedLesson.id,
      content: content,
    };
    try {
      const response = await updateNote(noteId, payload);
      console.log("updateNote response", response);
    } catch (error) {
      console.error("Error updating note:", error);
    } finally {
      setEditingNotes((prevEditingNotes) => ({
        ...prevEditingNotes,
        [noteId]: false,
      }));
      setNoteListChanged(false);
    }
  };

  // delete note
  const handleDeleteNote = async (noteId) => {
    try {
      await deleteNote(noteId);
      setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleBookmarksClick = () => {
    setBookmarksAccordianIsOpen(!bookmarksAccordianIsOpen);
  };


  const handleOpenDialog = () => {
    setNoteTimestamp(Math.floor(currentTime));
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setNoteTimestamp(0);
    setIsDialogOpen(false);
  };



  const handleSaveNote = async (noteTitle, noteContent) => {
    console.log("Note saved:", { noteTitle, noteContent });
    setNoteListChanged(true);
    const payload = {
      lesson: selectedLesson?.id,
      title: noteTitle,
      content: noteContent,
      timestamp: noteTimestamp,
    };

    try {
      const response = await createNote(selectedLesson?.id, payload);
      console.log(response);
      setNoteToastOpen(true);
      setNoteToastMessage("Note saved successfully!");
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      handleCloseDialog();
      setNoteListChanged(false);
    }
  };

  const [courseNotesAccordianIsOpen, setCourseNotesAccordianIsOpen] =
    useState(false);
  const handleCourseNotesClick = () => {
    setCourseNotesAccordianIsOpen(!courseNotesAccordianIsOpen);
  };

  // bookmarks

  useEffect(() => {
    if (!bookmarkListChanged) {
      const getBookmarks = async () => {
        try {
          const response = await listBookmarks(course?.id);
          console.log("bookmarksresponse", response);
          setBookmarks(response.data.results);
        } catch (error) {
          console.log(error);
        }
      };
      getBookmarks();
    }
  }, [bookmarkListChanged]);

  const formatTimestamp = (timestamp) => {
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleRemoveBookmark = async (bookmarkId) => {
    try {
      await deleteBookmark(bookmarkId);
      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark?.id !== bookmarkId)
      );
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  };

  // notes

  const downloadNotes = (notes) => {
    const notesContent = notes
      ?.map((note) => `${note?.title}\n\n${note?.content}\n\n\n\n`)
      ?.join("");

    const element = document.createElement("a");
    const file = new Blob([notesContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${course.name}_notes.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const printNotes = (notes) => {
    const notesContent = notes
      .map(
        (note) => `
          <div style="margin-bottom: 20px;">
            <div style="border: 1px solid #ccc; padding: 10px; margin-bottom: 10px;">
              <div style="border: 1px solid #ccc; padding: 10px; margin-bottom: 10px;">
                <div>
                ${note?.title}
                </div>
                <div>
                ${formatTimestamp(note?.timestamp)}
                </div>
              </div>
              ${note?.content}
            </div>
          </div>
        `
      )
      .join("");

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${course?.name} Notes</title>
          <style>
            body {
              font-family: Arial, sans-serif;
            }
            .note {
              margin-bottom: 20px;
            }
            .note-content {
              border: 1px solid #ccc;
              padding: 10px;
              margin-bottom: 10px;
            }
          </style>
        </head>
        <body>
          <h1>${course?.name} Note</h1>
          ${notesContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  //
  const [disableBookmark, setDisableBookmark] = useState(null);
  const [bookmarkMessage, setBookmarkMessage] = useState(null);
  const [bookmarkToastOpen, setBookmarkToastOpen] = useState(false);

  const handleAddBookmark = async () => {
    if (disableBookmark) return;

    setDisableBookmark(true);
    setBookmarkListChanged(true);
    console.log("Adding bookmark:", { selectedLesson, currentTime });
    try {
      const response = await createBookmark(selectedLesson.id, currentTime);
      setBookmarks((prevBookmarks) => [...prevBookmarks, response.data]);

      setBookmarkMessage("Bookmark added successfully!");
      setBookmarkToastOpen(true);
    } catch (error) {
      console.error("Error adding bookmark:", error);
      if (
        error?.response?.data?.error ===
        "Maximum bookmarks limit of 10 lectures per course reached."
      ) {
        setBookmarkMessage(
          "You have reached the maximum number of bookmarks for this course."
        );
        setBookmarkToastOpen(true);
      }
    } finally {
      setDisableBookmark(false);
      setBookmarkListChanged(false);
    }
  };


  function showEllipsis(text, maxLength = 40) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  return (
    <>
      <Toast
        open={bookmarkToastOpen}
        onClose={() => {
          setBookmarkToastOpen(false);
          setBookmarkMessage("");
        }}
        message={bookmarkMessage}
      />

      <Toast
        open={noteToastOpen}
        onClose={() => {
          setNoteToastOpen(false);
          setNoteToastMessage("");
        }}
        message={NoteToastMessage}
      />
      <Box>
        <Block>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            onClick={handleBookmarksClick}
          >
            <Stack flexDirection="row">
              <Badge
                badgeContent={
                  <Typography
                    fontSize={pxToRem(12)}
                    fontWeight={700}
                    color="#BFBEBB !important"
                  >
                    {bookmarks?.length} / 10
                  </Typography>
                }
                sx={{
                  mr: { ssm: pxToRem(90) },
                  "& .MuiBadge-badge": {
                    right: pxToRem(-33),
                    height: pxToRem(27),
                    borderRadius: pxToRem(20),
                    backgroundColor: "#EDECE8",
                    p: `${pxToRem(5)} ${pxToRem(10)}`,
                  },
                }}
              >
                <Typography
                  position="relative"
                  fontSize={pxToRem(20)}
                  fontWeight={700}
                >
                  Bookmarks
                </Typography>
              </Badge>
            </Stack>
            <Stack flexDirection="row" columnGap={{ xs: "12px", ssm: 0 }}>
              {!isCourseCompleted && (
                <Button
                  variant="yellow"
                  sx={{
                    height: `${pxToRem(40)} !important`,
                    fontSize: pxToRem(14),
                    p: {
                      xs: `${pxToRem(20)}`,
                      ssm: `${pxToRem(20)} ${pxToRem(30)}`,
                    },
                    boxShadow: "none",
                  }}
                  onClick={jumpToLatestProgress}
                >
                  {mobile_ssm ? "Latest" : "Jump to Latest Progress"}
                </Button>
              )}
              <Box
                sx={{
                  display: { xs: "flex", ssm: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: pxToRem(4),
                }}
              >
                <img
                  src={Chevron}
                  style={{
                    transform: bookmarksAccordianIsOpen
                      ? "rotate(-90deg)"
                      : "rotate(90deg)",
                    transition: "all 0.3s ease-in-out",
                    height: pxToRem(15),
                  }}
                  alt="Chevron"
                />
              </Box>
            </Stack>
          </Stack>
          <Box
            ref={bookmarksRef}
            sx={{
              overflow: "hidden",
              maxHeight: {
                xs: bookmarksAccordianIsOpen
                  ? `${bookmarksRef.current.scrollHeight}px`
                  : "0px",
                ssm: "100%",
              },
            }}
            style={{ transition: "max-height 0.3s ease-in-out" }}
          >
            <Box
              sx={{
                display: "grid",
                mt: pxToRem(24),
                gridTemplateColumns: {
                  ssm: bookmarks.length > 4 ? "1fr 1fr" : "1fr",
                },
                gap: `0 ${pxToRem(40)}`,
              }}
            >
              {bookmarks.map((bookmark) => (
                <Stack
                  key={bookmark.id}
                  // to="/"
                  // component={Link}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  py={pxToRem(10)}
                  sx={{
                    // "&:first-child": {mt: pxToRem(24)},
                    position: "relative",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100vw",
                      height: "1px",
                      backgroundColor: "#DFDEDD",
                    },
                    "&:hover": {
                      "& .text": {
                        color: "#333",
                      },
                      "& .bookmark-number": {
                        backgroundColor: "#FCE181",
                        span: {
                          color: "#026670",
                        },
                      },
                      "& .deleteBtn": {
                        display: "flex",
                      },
                    },
                  }}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    columnGap={pxToRem(15)}
                    onClick={() =>
                      jumpToBookMark(bookmark?.timestamp, bookmark?.lesson?.id)
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Box
                      className="bookmark-number"
                      width={pxToRem(27)}
                      height={pxToRem(27)}
                      borderRadius="100%"
                      bgcolor="#EDECE8"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexShrink={0}
                    >
                      <Typography
                        component="span"
                        fontSize={pxToRem(12)}
                        fontWeight={700}
                        color="#BFBEBB"
                      >
                        {bookmarks?.indexOf(bookmark) + 1}
                      </Typography>
                    </Box>
                    <Typography
                      className="text"
                      display="block"
                      fontSize={pxToRem(16)}
                      lineHeight={pxToRem(20)}
                      fontWeight={600}
                      color="secondary"
                    >
                      {bookmark?.lesson?.name}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    sx={{ "& svg": { mb: "1px" } }}
                  >
                    <DeleteBtn
                      onDelete={() =>
                        handleRemoveBookmark(bookmark?.id, bookmark?.timestamp)
                      }
                    />
                    <Typography
                      fontSize={pxToRem(16)}
                      fontWeight={600}
                      color="primary"
                    >
                      {formatTimestamp(bookmark?.timestamp)}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </Box>
        </Block>
        <Box display="flex" justifyContent="flex-end">
          <TextLink
            sx={{
              marginTop: pxToRem(20),
              marginRight: { xs: pxToRem(25), ssm: pxToRem(40) },
            }}
            onClick={handleAddBookmark}
          >
            Add Bookmark
          </TextLink>
        </Box>
      </Box>

      <Box mt={{ xs: pxToRem(30), ssm: pxToRem(60) }}>
        <Block>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            onClick={handleCourseNotesClick}
            marginBottom={pxToRem(20)}
          >
            <Stack flexDirection="row">
              <Typography fontSize={pxToRem(20)} fontWeight={700}>
                Course Notes
              </Typography>
            </Stack>
            <Stack flexDirection="row" columnGap={{ xs: "20px", ssm: 0 }}>
              {notes?.length > 0 && (
                <Stack
                  flexDirection="row"
                  gap={pxToRem(10)}
                  sx={{ "& button": { backgroundColor: "#EDECE8" } }}
                >
                  <Tooltip
                    title="Download notes to text file"
                    enterDelay={0}
                    enterTouchDelay={0}
                    placement="top"
                  >
                    <Box>
                      <IconBtn
                        className="hover-green"
                        onClick={() => downloadNotes(notes)}
                      >
                        <Download color="#026670" />
                      </IconBtn>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title="Print Notes"
                    enterDelay={0}
                    enterTouchDelay={0}
                    placement="top"
                  >
                    <Box>
                      <IconBtn
                        className="hover-green"
                        onClick={() => printNotes(notes)}
                      >
                        <Print color="#026670" />
                      </IconBtn>
                    </Box>
                  </Tooltip>
                </Stack>
              )}
              <Box
                sx={{
                  display: { xs: "flex", ssm: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: pxToRem(4),
                }}
              >
                <img
                  src={Chevron}
                  style={{
                    transform: courseNotesAccordianIsOpen
                      ? "rotate(-90deg)"
                      : "rotate(90deg)",
                    transition: "all 0.3s ease-in-out",
                    height: pxToRem(15),
                  }}
                  alt="Chevron"
                />
              </Box>
            </Stack>
          </Stack>
          {notes?.map((note) => {
            const isEditing = editingNotes[note.id] || false;
            const content = noteContents[note.id] || note.content;

            return (
              <Stack key={note?.id}>
                <Note note={note} onDelete={() => handleDeleteNote(note.id)}>
                  <BlockBordered mb={pxToRem(20)}>
                    <Box className="note-edited">
                      {isEditing ? (
                        <TextField
                          value={noteContents[note.id] || ""}
                          onChange={(e) => {
                            setNoteContents((prevNoteContents) => ({
                              ...prevNoteContents,
                              [note.id]: e.target.value,
                            }));
                          }}
                          multiline
                          fullWidth
                        />
                      ) : (
                        <Typography variant="medium" lineHeight={pxToRem(22)}>
                          {/* {showEllipsis(note?.content)} */}
                          {mobile_ssm ? showEllipsis(note?.content,27):showEllipsis(note?.content)}
                        </Typography>
                      )}
                    </Box>
                  </BlockBordered>
                  <TextLink
                    href=""
                    sx={{
                      marginBottom: pxToRem(10),
                      "& span": { fontSize: pxToRem(14) },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (isEditing) {
                        // Save Note
                        handleUpdateNote(note.id, noteContents[note.id]);
                      } else {
                        // Edit Note
                        setEditingNotes((prevEditingNotes) => ({
                          ...prevEditingNotes,
                          [note.id]: true,
                        }));
                        setNoteContents((prevNoteContents) => ({
                          ...prevNoteContents,
                          [note.id]: note.content,
                        }));
                      }
                    }}
                  >
                    {isEditing ? "Save Note" : "Edit Note"}
                  </TextLink>
                </Note>
              </Stack>
            );
          })}
        </Block>
        <Box display="flex" justifyContent="flex-end">
          <TextLink
            sx={{
              marginTop: pxToRem(20),
              marginRight: { xs: pxToRem(25), ssm: pxToRem(40) },
            }}
            onClick={handleOpenDialog}
          >
            Add Note
          </TextLink>
        </Box>
      </Box>

      <AddNoteDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveNote}
      />
    </>
  );
};

export default NotesBookmarks;
