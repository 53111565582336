import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import Dropzone from "react-dropzone";
import { useState } from "react";
import { editProfilePicture } from "../../../api/student";
import ChangeSuccess from "../../../components/ChangeSuccess";
import { saveStudentLocal } from "../../../utils/AuthContext";
import { getUserInfo } from "../../../api/student";
import ModalHeader from "../../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";
import Toast from "../../../components/Toast";

const ButtonStyled = styled(Button)({
  color: "#EDECE8",
  width: pxToRem(200),
  height: pxToRem(50),
  color: "#026670",
  borderRadius: `${pxToRem(30)} !important`,
  fontSize: pxToRem(16),
  fontWeight: 600,
  textTransform: "capitalize",
  backgroundColor: "#ffdc74",
  border: "none",
  "&:hover": {
    backgroundColor: "#BFBEBB",
    color: "#026670",
  },
});
export default function EditProfilePicture({ open, setOpen, update }) {
  const userInfo = getUserInfo();

  const [profilePic, setProfilePic] = useState({ preview: userInfo.avatar });
  const [profilePicPath, setProfilePicPath] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDropChange = (acceptedFiles, set) => {
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  function handleSubmit() {
    setLoading(true);
    const formData = new FormData();
    if (profilePic) {
      formData.append("avatar", profilePic);
      editProfilePicture(formData)
        .then((res) => {
          console.log(res);
          setOpen(false);
          setOpenSuccess(true);
          setLoading(false);
          update();
          saveStudentLocal().then((res) => {
            window.location.href = "/user/account-settings";
          });
        })
        .catch((err) => {
          console.log("err is", err);
          setLoading(false);
        });
    }
  }
  const handleFileChange = (event) => {
    setProfilePic(event.target.files[0]);
    setProfilePicPath(event.target.files[0].name);
    setProfilePicPreview(URL.createObjectURL(event.target.files[0]));
  };
  console.log("profilePic is", profilePic);
  return (
    <>
     
      <Toast
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        message={"Your profile picture has been succesfully updated!"}
      />

      <Dialog
        fullWidth
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
            minHeight: "500px",
            borderRadius: "15px",
          },
        }}
      >
        <ModalHeader
          title="Edit Profile Picture"
          inModal
          onClose={() => setOpen(false)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-25px",
            backgroundColor: "#f7f6f2",
          }}
        >
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <ButtonStyled component="span">Upload a new picture</ButtonStyled>
          </label>
        </Box>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f7f6f2",
          }}
        >
          {profilePic && (
            <div
              key={profilePic.name}
              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
            >
              <div
                className="dz-image"
                style={{
                  width: 300,
                  height: 300,
                  transition: "filter 0.3s",
                  position: "relative",
                }}
              >
                <img
                  src={profilePic.preview || profilePicPreview}
                  alt="preview"
                  style={{ filter: "blur(0)" }}
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "30px",
            backgroundColor: "#f7f6f2",
          }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="success"
            size="small"
            sx={{
              backgroundColor: "#035416",
              padding: "10px 30px ",
              color: "white",
            }}
            disabled={loading}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              setProfilePic({ preview: userInfo.avatar });
            }}
            variant="contained"
            color="error"
            size="small"
            sx={{
              backgroundColor: "#a61900",
              padding: "10px 30px",
              color: "white",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
